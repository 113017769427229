import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from 'components/Table/Index';
import { useFetchPendingWireTransactions, useFetchTransactions } from 'store/reducers/transactions';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useToggle from 'hooks/useToggle';
import TransactionDialog from 'views/admin/transactions/TransactionDialog';
import { formatISODateString } from 'utils/helpers';

const useStyles = makeStyles(() => ({
  content: {
    padding: 20,
  },
}));
const WireIndex = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { view } = useParams();
  const history = useHistory();
  const { transactions, pendingWireTransactions } = useSelector((state) => state.transactions);
  const [, fetchAllTransactions] = useFetchTransactions();
  const [, fetchPendingTransactions] = useFetchPendingWireTransactions();

  let transactionsData = [],
    fetched = false,
    fetchTransactions;

  if (view === 'all') {
    transactionsData = transactions;
    fetched = transactions !== undefined;
    fetchTransactions = fetchAllTransactions;
  } else if (view === 'pending') {
    transactionsData = pendingWireTransactions;
    fetched = pendingWireTransactions !== undefined;
    fetchTransactions = fetchPendingTransactions;
  }

  useEffect(() => {
    if (!view) {
      history.replace({ pathname: '/admin' });
    }
  }, [view, history]);

  useEffect(() => {
    if (!fetched && fetchTransactions) {
      fetchTransactions();
    }
  }, [fetched, view, fetchTransactions]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('date'),
        accessor: 'createdAt',
        Cell: (x) => {
          return formatISODateString(x.value);
        },
      },
      {
        Header: t('wire-transfer-message-identifier'),
        accessor: 'wireTransferMessageIdentifier',
      },
      {
        Header: t('remitter-account-name'),
        accessor: 'remitterAccountName',
      },
      {
        Header: t('status'),
        accessor: 'status',
      },
      {
        Header: t('type'),
        accessor: 'type',
      },
      {
        Header: t('amount'),
        accessor: 'amount',
      },
    ],
    [t]
  );

  const [showDetailsDialog, toggleShowDetailsDialog] = useToggle();
  const [selectedTransactionId, setSelectedTransactionId] = useState();
  const handleShowTransactionDetails = (transaction) => {
    setSelectedTransactionId(transaction.id);
    toggleShowDetailsDialog();
  };
  const actions = [
    {
      type: 'func',
      onClick: handleShowTransactionDetails,
      icon: 'open_in_full',
      tooltip: t('show-details'),
    },
  ];

  return (
    <>
      <AppBar position="static">
        <Tabs value={view ?? 'all'}>
          <Tab label={t('all-transactions')} component={Link} to="/admin/transactions/wire/all" value="all" />
          <Tab
            label={t('pending-transactions')}
            component={Link}
            to="/admin/transactions/wire/pending"
            value="pending"
          />
        </Tabs>
      </AppBar>
      <TransactionDialog
        show={showDetailsDialog}
        toggle={toggleShowDetailsDialog}
        transactionId={selectedTransactionId}
      />
      {!fetched ? (
        <LinearProgress />
      ) : (
        <div className={classes.content}>
          <Table columns={columns} data={transactionsData} actions={actions} />
        </div>
      )}
    </>
  );
};

export default WireIndex;
