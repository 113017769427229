import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import useAsyncFn from 'hooks/useAsyncFn';
import {
  createCurrencyAsync,
  disableCurrencyAsync,
  enableCurrencyAsync,
  fetchCurrenciesAsync,
  updateCurrencyAsync,
} from 'transfer/admin/currenciesApi';

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState: {
    fetched: false,
    data: [],
  },
  reducers: {
    addCurrencies(state, { payload }) {
      state.fetched = true;
      state.data = payload;
    },
    addCurrency(state, { payload }) {
      state.data.push(payload);
    },
    updateCurrency(state, { payload }) {
      const currencyIndex = state.data.findIndex((currency) => currency.id === payload.id);
      if (currencyIndex >= 0) {
        state.data[currencyIndex] = payload;
      }
    },
    toggleCurrencyEnabled(state, { payload }) {
      const currency = state.data.find((currency) => currency.id === payload);
      if (currency) {
        currency.enabled = !currency.enabled;
      }
    },
  },
});

export const { addCurrencies, addCurrency, updateCurrency, toggleCurrencyEnabled } = currenciesSlice.actions;

export default currenciesSlice.reducer;

export function useFetchCurrencies() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const currencies = await fetchCurrenciesAsync();
    if (currencies) {
      dispatch(addCurrencies(currencies));
    }
    return currencies;
  }, [dispatch]);
}

export function useCreateCurrency() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const currency = await createCurrencyAsync(data);
      if (currency) {
        dispatch(addCurrency(currency));
      }
      return currency;
    },
    [dispatch]
  );
}

export function useUpdateCurrency() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (currencyId, data) => {
      const currency = await updateCurrencyAsync(currencyId, data);
      if (currency) {
        dispatch(updateCurrency(currency));
      }
      return currency;
    },
    [dispatch]
  );
}

export function useToggleCurrency() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (enabled, currencyId) => {
      dispatch(toggleCurrencyEnabled(currencyId));
      let updateSuccessful;
      if (enabled) {
        updateSuccessful = await disableCurrencyAsync(currencyId);
      } else {
        updateSuccessful = await enableCurrencyAsync(currencyId);
      }
      if (!updateSuccessful) {
        const currencies = await fetchCurrenciesAsync();
        dispatch(addCurrencies(currencies));
      }
    },
    [dispatch]
  );
}
