import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import useAsyncFn from 'hooks/useAsyncFn';
import { fetchUsersAsync } from 'transfer/admin/users';
import { roles } from 'utils/enums';

const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    allClients: {
      fetched: false,
      data: [],
    },
    verifiedClients: {
      fetched: false,
      data: [],
    },
  },
  reducers: {
    addAllClients(state, { payload }) {
      return {
        ...state,
        allClients: {
          fetched: true,
          data: payload,
        },
      };
    },
    addVerifiedClients(state, { payload }) {
      return {
        ...state,
        verifiedClients: {
          fetched: true,
          data: payload,
        },
      };
    },
  },
});

export const { addAllClients, addVerifiedClients } = clientsSlice.actions;

export default clientsSlice.reducer;

export function useFetchAllClients() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const clients = await fetchUsersAsync();
    dispatch(addAllClients(clients.list));
    return clients;
  }, [dispatch]);
}

export function useFetchVerifiedClients() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const clients = await fetchUsersAsync(roles.VerifiedUser);
    dispatch(addVerifiedClients(clients.list));
    return clients;
  }, [dispatch]);
}
