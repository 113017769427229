import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuthenticateUser } from 'store/reducers/user';
import Routes from 'Routes';
import Layout from 'layout';
import { initApi } from 'transfer/config';
import { useFetchCountries } from 'store/reducers/countries';

function App({ store }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    fetched: userFetched,
    authenticated,
    data: { roles },
  } = useSelector((state) => state.user);
  const { fetched: countriesFetched } = useSelector((state) => state.countries);
  const [, authenticate] = useAuthenticateUser();
  const [, fetchCountries] = useFetchCountries();

  useEffect(() => {
    initApi(history, store);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!userFetched) {
      authenticate();
    } else if (authenticated && roles.includes('Admin') && !pathname.includes('admin')) {
      history.push('/admin');
    }
  }, [history, userFetched, authenticate, authenticated, roles, pathname]);

  useEffect(() => {
    if (!countriesFetched) {
      fetchCountries();
    }
  }, [countriesFetched, fetchCountries]);

  return (
    <>
      <Layout signedIn={authenticated && roles.includes('Admin')}>
        <Routes />
      </Layout>
    </>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
