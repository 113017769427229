import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide from '@material-ui/core/Slide';

import { fetchTransactionAsync } from 'transfer/admin/transactionsApi';
import { formatISODateString, formatPrice } from 'utils/helpers';
import ApproveTransactionForm from './ApproveTransactionForm';
import SetPendingTransactionForm from './SetPendingTransactionForm';
import RejectTransactionForm from './RejectTransactionForm';

const Transition = forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  formControlLabelRoot: {
    marginLeft: -8,
  },
  paper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    maxWidth: 500,
    height: 'unset',
    margin: 15,
    borderRadius: 4,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
  },
  title: {
    margin: '20px 0 6px',
    fontSize: 18,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  textContent: {
    whiteSpace: 'pre-line',
  },
  actions: {
    marginTop: 16,
  },
  actionContent: {
    flexGrow: 1,
  },
}));

const TransactionDialog = ({ show, toggle, transactionId }) => {
  const classes = useStyles();
  const { t } = useTranslation(['translation', 'views']);
  const [transaction, setTransaction] = useState();
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState('');

  useEffect(() => {
    if (!transaction && transaction?.method !== 'WireTransfer') return;
    if (transaction?.type === 'Deposit') {
      const newActions = transaction?.status === 'Pending' ? ['approve', 'reject'] : ['set-pending'];
      setActions(newActions);
      setSelectedAction(newActions[0]);
    }
    if (transaction?.type === 'Withdrawal') {
      const newActions = transaction?.status === 'Pending' ? ['approve', 'reject'] : ['set-pending'];
      setActions(newActions);
      setSelectedAction(newActions[0]);
    }
  }, [transaction]);

  useEffect(() => {
    if (transactionId && show) {
      fetchTransactionAsync(transactionId).then((res) => {
        setTransaction(res);
      });
    }
  }, [transactionId, show]);

  function handleClose() {
    setTransaction(undefined);
    toggle();
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      fullScreen
      keepMounted={false}
      TransitionComponent={Transition}
      PaperProps={{
        className: classes.paper,
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle disableTypography className={classes.header}>
        <Typography variant="h6">{t('views:transactions.wire.transaction-dialog.title')}</Typography>
        <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {!transaction ? (
        <LinearProgress />
      ) : (
        <DialogContent dividers className={classes.content}>
          <DialogContentText>{t('views:transactions.wire.transaction-dialog.details')}</DialogContentText>
          <Typography>
            <b>{t('created')}:</b> {formatISODateString(transaction?.createdAt)}
          </Typography>
          <Typography>
            <b>{t('type')}:</b> {transaction?.type}
          </Typography>
          <Typography>
            <b>{t('method')}:</b> {transaction?.method}
          </Typography>
          <Typography>
            <b>{t('status')}:</b> {transaction?.status}
          </Typography>
          {transaction?.wirePromisedAmount && (
            <Typography component="div">
              <b>{t('promised-amount')}:</b>{' '}
              {formatPrice(transaction?.wirePromisedAmount, transaction?.wirePromisedAmountCurrency?.code)}
            </Typography>
          )}
          {transaction?.wireTransferMessageIdentifier && (
            <Typography component="div">
              <b>{t('wire-transfer-message-identifier')}:</b> {transaction?.wireTransferMessageIdentifier}
            </Typography>
          )}
          <>
            <Typography variant="subtitle2" className={classes.title}>
              {t('plan')}
            </Typography>
            <Typography>
              <b>{t('id')}:</b> {transaction.plan.id}
            </Typography>
            <Typography>
              <b>{t('name')}:</b> {transaction.plan.name}
            </Typography>
            <Typography>
              <b>{t('portfolio-id')}:</b> {transaction.portfolio.id}
            </Typography>
            <Typography>
              <b>{t('portfolio-name')}:</b> {transaction.portfolio.name}
            </Typography>
          </>
          {transaction?.bankAccount !== null && transaction?.method === 'WireTransfer' && (
            <>
              <Typography variant="subtitle2" className={classes.title}>
                {t('bankAccount')}
              </Typography>
              <Typography>
                <b>{t('accountHolderName')}:</b> {transaction.bankAccount.accountHolderName}
              </Typography>
              <Typography>
                <b>{t('accountNumber')}:</b> {transaction.bankAccount.accountNumber}
              </Typography>
              <Typography>
                <b>{t('bankName')}:</b> {transaction.bankAccount.bankName}
              </Typography>
              <Typography>
                <b>{t('swiftCode')}:</b> {transaction.bankAccount.swiftCode}
              </Typography>
            </>
          )}
          {transaction?.wireRejectionComment && (
            <Typography component="div">
              <b>{t('rejection-comment')}:</b>{' '}
              <div className={classes.textContent}>{transaction?.wireRejectionComment}</div>
            </Typography>
          )}
          {transaction?.internalComment && (
            <Typography>
              <b>{t('internal-comment')}:</b> <div className={classes.textContent}>{transaction?.internalComment}</div>
            </Typography>
          )}
          <FormControl component="fieldset" className={classes.actions}>
            <FormLabel component="legend">{t('translation:actions')}</FormLabel>
            <RadioGroup row value={selectedAction} onChange={(ev) => setSelectedAction(ev.target.value)}>
              {actions?.map((action) => (
                <FormControlLabel
                  key={`transaction-actions-${action}`}
                  value={action}
                  control={<Radio color="primary" />}
                  label={t(`translation:${action}`)}
                  labelPlacement="end"
                />
              ))}
            </RadioGroup>
          </FormControl>
          <div className={classes.actionContent}>
            {selectedAction === 'approve' ? (
              <ApproveTransactionForm
                transactionId={transaction?.id}
                previousInternalComment={transaction?.internalComment}
                type={transaction?.type}
                updateTransaction={setTransaction}
              />
            ) : selectedAction === 'set-pending' ? (
              <SetPendingTransactionForm
                updateTransaction={setTransaction}
                transactionId={transaction?.id}
                previousInternalComment={transaction?.internalComment}
              />
            ) : selectedAction === 'reject' ? (
              <RejectTransactionForm
                updateTransaction={setTransaction}
                transactionId={transactionId}
                previousInternalComment={transaction?.internalComment}
              />
            ) : null}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

TransactionDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  transactionId: PropTypes.string,
};

TransactionDialog.defaultProps = {
  editData: null,
};

export default TransactionDialog;
