import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
  },
  list: {
    maxWidth: 500,
    paddingTop: 4,
    marginBottom: 15,
    '& > li': {
      padding: '5px 0px',
      justifyContent: 'space-between',
    },
  },
}));

const Overview = ({ portfolio }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const items = [
    { label: t('name'), value: t(portfolio.name) },
    { label: t('num-of-active-plans'), value: portfolio.numberOfActivePlans },
    { label: t('num-of-fulfillment-orders'), value: portfolio.numberOfFulfillmentOrders },
    { label: t('share-price'), value: portfolio.sharePrice },
    { label: t('total-shares'), value: portfolio.totalShares },
    { label: t('cocoa-owned-shares'), value: portfolio.cocoaOwnedShares },
    { label: t('currency-name'), value: portfolio.currency?.name },
    { label: t('currency-code'), value: portfolio.currency?.code },
  ];

  return (
    <Grid container className={classes.root}>
      <Grid item xs={6}>
        <Typography variant="subtitle2">{t('general')}</Typography>
        <List className={classes.list}>
          {items.map((item) => (
            <ListItem key={item.label}>
              <div>{item.label}</div>
              <div>{item.value}</div>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

Overview.propTypes = {
  portfolio: PropTypes.object.isRequired,
};

export default Overview;
