import { createSlice } from '@reduxjs/toolkit';
import useAsyncFn from 'hooks/useAsyncFn';
import { useDispatch } from 'react-redux';
import { fetchPortfolioAsync, fetchPortfoliosAsync, fetchPortfolioPlansAsync } from 'transfer/admin/portfoliosApi';

const portfoliosSlice = createSlice({
  name: 'portfolios',
  initialState: {
    fetched: false,
    data: [],
  },
  reducers: {
    addPortfolios(state, { payload }) {
      state.data = payload;
      state.fetched = true;
    },
  },
});

export const { addPortfolios } = portfoliosSlice.actions;

export default portfoliosSlice.reducer;

export function useFetchPortfolios() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const portfolios = await fetchPortfoliosAsync();
    dispatch(addPortfolios(portfolios));
    return portfolios;
  }, [dispatch]);
}

export function useFetchPortfolio() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id) => {
      return await fetchPortfolioAsync(id);
    },
    [dispatch]
  );
}

export function useFetchPortfolioPlans() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id) => {
      const plans = await fetchPortfolioPlansAsync(id);
      return plans.list;
    },
    [dispatch]
  );
}
