import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormDialog from 'components/Table/FormDialog';
import { updatePortfolioHoldings } from 'transfer/admin/portfoliosApi';

const UpdateHoldingDialog = ({ show, toggle }) => {
  const { t } = useTranslation(['translation', 'views']);

  const { handleSubmit, reset } = useForm();

  const [selectedFiles, setSelectedFiles] = useState({
    cashBalance: undefined,
    holdingBalance: undefined,
  });
  const [disabled, setDisabled] = useState(false);

  const selectFile = (type) => (event) => {
    console.log(type, event);
    setSelectedFiles({
      ...selectedFiles,
      [type]: event.target.files?.length === 0 ? undefined : event.target.files,
    });
  };

  const clearUpload = () => {
    setSelectedFiles({
      cashBalance: undefined,
      holdingBalance: undefined,
    });
    setDisabled(false);
  };

  const handleClose = () => {
    clearUpload();
    reset();
    toggle();
  };

  const onSubmit = async () => {
    if (
      !selectedFiles.cashBalance ||
      selectedFiles.cashBalance.length === 0 ||
      !selectedFiles.holdingBalance ||
      selectedFiles.holdingBalance.length === 0
    )
      return;
    setDisabled(true);
    let formData = new FormData();
    formData.append('CashBalance', selectedFiles.cashBalance[0]);
    formData.append('HoldingBalance', selectedFiles.holdingBalance[0]);
    try {
      await updatePortfolioHoldings(formData);
      handleClose();
    } finally {
      setDisabled(false);
    }
  };

  return (
    <FormDialog
      open={show}
      title={t('views:portfolios.index.update-holding-dialog.title')}
      description={t('views:portfolios.index.update-holding-dialog.description')}
      toggle={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
      submitDisabled={!selectedFiles.holdingBalance || !selectedFiles.cashBalance || disabled}
      loading={disabled}
      submitText={t('upload')}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button startIcon={<AddIcon />} fullWidth={true} size="small" component={'label'} disabled={disabled}>
            {t('cash-balance')}
            <input
              type="file"
              onChange={selectFile('cashBalance')}
              hidden
              key={`cash-file-${selectedFiles.cashBalance && selectedFiles.cashBalance[0]?.name}`}
            />
          </Button>
          <p>{selectedFiles.cashBalance && (selectedFiles.cashBalance[0]?.name ?? '')}</p>
        </Grid>
        <Grid item xs={12}>
          <Button startIcon={<AddIcon />} fullWidth={true} size="small" component={'label'} disabled={disabled}>
            {t('holding-balance')}
            <input
              type="file"
              onChange={selectFile('holdingBalance')}
              hidden
              key={`holding-file-${selectedFiles.holdingBalance && selectedFiles.holdingBalance[0]?.name}`}
            />
          </Button>
          <p>{selectedFiles.holdingBalance && (selectedFiles.holdingBalance[0]?.name ?? '')}</p>
        </Grid>
      </Grid>
    </FormDialog>
  );
};

UpdateHoldingDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default UpdateHoldingDialog;
