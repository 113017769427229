import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import Table from 'components/Table/Index';

const ManagementFees = ({ fees }) => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t('id').toUpperCase(),
        accessor: 'id',
        Cell: (x) => t(x.value),
      },
      {
        Header: t('shares'),
        accessor: 'shares',
      },
      {
        Header: t('amount'),
        accessor: 'amount',
      },
      {
        Header: t('fee-fraction'),
        accessor: 'managementFeeRange.feeFraction',
        Cell: (x) => `${(x.value * 100).toFixed(2)}%`,
      },
    ],
    [t]
  );
  console.log('FEES', fees);

  const data = React.useMemo(
    () =>
      fees?.map((fees) => ({
        ...fees,
        /* linkPath: `/admin/fees/${fees.id}`, */
      })),
    [fees]
  );

  return (
    <Grid container direction="column">
      {!fees ? <LinearProgress /> : fees?.length === 0 ? t('no-items') : <Table data={data} columns={columns} />}
    </Grid>
  );
};

ManagementFees.propTypes = {
  fees: PropTypes.array.isRequired,
};

export default ManagementFees;
