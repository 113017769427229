import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Switch, Route, useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Overview from './show/Overview';
import { useFetchFulfillmentOrder, useNetFulfillmentOrder } from 'store/reducers/fulfillmentOrders';
import CompleteFulfillmentOrder from './show/CompleteFulfillmentDialog';
import useToggle from '../../../hooks/useToggle';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    fontWeight: 300,
  },
  content: {
    margin: 10,
    display: 'flex',
  },
  contentError: {
    padding: 20,
  },
}));

const PortfoliosShow = () => {
  const classes = useStyles();
  const { id, view } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [, fetchFulfillmentOrder] = useFetchFulfillmentOrder();
  const [, netFulfillmentOrder] = useNetFulfillmentOrder();
  const [netted, setNetted] = useState();
  const [fulfilled, setFulfilled] = useState();
  const fulfillmentOrder = useSelector((state) => state.fulfillmentOrders.data.find((o) => o.id === parseInt(id)));
  const [showCompleteDialog, toggleShowCompleteDialog] = useToggle();

  useEffect(() => {
    if (!view) {
      history.replace({ pathname: `/admin/fulfillment-orders/${id}/overview` });
    }
  }, [view, history, id]);

  useEffect(() => {
    if (id) {
      fetchFulfillmentOrder(id).catch((e) => {
        console.log(e.response);
        history.replace({ pathname: `/admin/fulfillment-orders` });
      });
    }
  }, [id, fetchFulfillmentOrder, history]);

  useEffect(() => {
    if (fulfillmentOrder) {
      if (fulfillmentOrder.internallyNettedAt) {
        setNetted(true);
      } else {
        setNetted(false);
      }
      if (fulfillmentOrder.fulfilledAt) {
        setFulfilled(true);
      } else {
        setFulfilled(false);
      }
    }
  }, [fulfillmentOrder]);

  if (!fulfillmentOrder) return <LinearProgress />;

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h3">
          {t('fulfillment-order')}: {fulfillmentOrder.id}
        </Typography>
        <div>
          <Button
            onClick={() => netFulfillmentOrder(id)}
            startIcon={<AddIcon />}
            fullWidth={false}
            size="small"
            disabled={netted}
            style={{ marginRight: 10 }}
          >
            {t('net-order')}
          </Button>
          <Button
            onClick={toggleShowCompleteDialog}
            startIcon={<AddIcon />}
            fullWidth={false}
            size="small"
            disabled={fulfilled}
          >
            {t('complete-order')}
          </Button>
        </div>
      </div>

      <CompleteFulfillmentOrder
        key={`${fulfillmentOrder.id}-upsert-dialog-pricing`}
        show={showCompleteDialog}
        toggle={toggleShowCompleteDialog}
        id={id}
        fulfillmentOrder={fulfillmentOrder}
      />

      <Paper className={classes.content}>
        <Switch>
          <Route
            exact
            path="/admin/fulfillment-orders/:id/overview"
            component={() => <Overview fulfillmentOrder={fulfillmentOrder} />}
          />
        </Switch>
      </Paper>
      <div />
    </>
  );
};

export default PortfoliosShow;
