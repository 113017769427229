import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import FormDialog from 'components/Table/FormDialog';
import { useFetchAsset } from 'store/reducers/assets';
import { useCreateAsset, useUpdateAsset } from 'store/reducers/assets';
import { AssetClassEnum } from 'utils/enums';

const useStyles = makeStyles(() => ({
  assetClassSelect: {
    '& legend': {
      visibility: 'visible',
    },
  },
}));

const UpsertAssetDialog = ({ show, toggle, editData }) => {
  const classes = useStyles();
  const { t } = useTranslation(['translation', 'views']);
  const [regionalBreakdown, setRegionalBreakdown] = useState();
  const [sectionalBreakdown, setSectionalBreakdown] = useState();
  const [staticHistoricalPerformance, setStaticHistoricalPerformance] = useState();
  const [{ loading: loadingCreate }, createAsset] = useCreateAsset();
  const [{ loading: loadingUpdate }, updateAsset] = useUpdateAsset();
  const [, fetchAsset] = useFetchAsset();

  const disabled = loadingCreate || loadingUpdate;
  const schema = useMemo(
    () =>
      yup.object().shape({
        marketTickerSymbol: yup.string().required(t('form-validations.required')),
        name: yup.string().required(t('form-validations.required')),
        isin: yup.string().required(t('form-validations.required')),
        color: yup.string().required(t('form-validations.required')),
        assetClass: yup.string(),
        description: yup.string(),
      }),
    [t]
  );

  const { register, errors, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (!regionalBreakdown || !staticHistoricalPerformance || !sectionalBreakdown) return;
    if (editData) {
      if (
        await updateAsset(editData.id, {
          ...data,
          regionalBreakdown,
          sectionalBreakdown,
          staticHistoricalPerformance,
          id: editData.id,
        })
      ) {
        toggle();
      }
    } else if (
      await createAsset({
        ...data,
        regionalBreakdown,
        sectionalBreakdown,
        staticHistoricalPerformance,
      })
    ) {
      toggle();
    }
  };

  useEffect(() => {
    if (!editData) return reset();
    fetchAsset(editData?.id).then((res) => {
      reset({
        marketTickerSymbol: res?.marketTickerSymbol ?? '',
        name: res?.name ?? '',
        isin: res?.isin ?? '',
        color: res?.color ?? '',
        assetClass: res?.assetClass ?? Object.keys(AssetClassEnum)[0],
        description: res?.description ?? '',
        regionalBreakdown: res?.regionalBreakdown ?? '',
        sectionalBreakdown: res?.sectionalBreakdown ?? '',
        staticHistoricalPerformance: res?.staticHistoricalPerformance ?? '',
      });
      setSectionalBreakdown(res?.sectionalBreakdown ?? { section1: 0.3, section3: 0.4, section2: 0.3 });
      setRegionalBreakdown(res?.regionalBreakdown ?? { region1: 0.3, region2: 0.4, region4: 0.3 });
      setStaticHistoricalPerformance(res?.staticHistoricalPerformance ?? { '6m': 13.31, '1Y': 2.44, 2009: 182 });
    });
  }, [reset, editData, fetchAsset]);

  return (
    <FormDialog
      open={show}
      title={t(`views:admin.assets.upsert-dialog.${editData ? 'edit' : 'create'}-title`)}
      description={t(`views:admin.assets.upsert-dialog.${editData ? 'edit' : 'create'}-description`)}
      toggle={toggle}
      disabled={disabled}
      onSubmit={handleSubmit(onSubmit)}
      submitText={editData ? t('update') : t('create')}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="marketTickerSymbol"
            label={t('marketTickerSymbol')}
            type="text"
            inputRef={register}
            error={Boolean(errors.marketTickerSymbol)}
            helperText={errors.marketTickerSymbol?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="name"
            label={t('name')}
            type="text"
            inputRef={register}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="isin"
            label={t('isin')}
            type="text"
            inputRef={register}
            error={Boolean(errors.isin)}
            helperText={errors.isin?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="color"
            label={t('colorHEXvalue')}
            type="text"
            inputRef={register}
            error={Boolean(errors.color)}
            helperText={errors.color?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <Select
              label={t('asset-class')}
              labelId="asset-class-select"
              native
              className={classes.assetClassSelect}
              inputProps={{
                name: 'assetClass',
                ref: register,
                id: 'asset-class-select',
              }}
            >
              {Object.keys(AssetClassEnum).map((name) => (
                <option key={name} value={name}>
                  {t(`asset-class-enum.${name}`)}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="description"
            label={t('description')}
            type="text"
            multiline
            rows={6}
            inputRef={register}
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{t('views:admin.assets.upsert-dialog.regional-breakdown-text')}</Typography>
          <JSONInput
            placeholder={regionalBreakdown}
            locale={locale}
            onChange={(value) => setRegionalBreakdown(value.jsObject)}
            width="100%"
            height={250}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{t('views:admin.assets.upsert-dialog.sectional-breakdown-text')}</Typography>
          <JSONInput
            placeholder={sectionalBreakdown}
            locale={locale}
            onChange={(value) => setSectionalBreakdown(value.jsObject)}
            width="100%"
            height={250}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{t('views:admin.assets.upsert-dialog.historical-performance-text')}</Typography>
          <JSONInput
            placeholder={staticHistoricalPerformance}
            locale={locale}
            onChange={(value) => setStaticHistoricalPerformance(value.jsObject)}
            width="100%"
            height={250}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

UpsertAssetDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  editData: PropTypes.shape({
    id: PropTypes.number,
    marketTickerSymbol: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isin: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    regionalBreakdown: PropTypes.object.isRequired,
    sectionalBreakdown: PropTypes.object.isRequired,
  }),
};

UpsertAssetDialog.defaultProps = {
  editData: null,
};

export default UpsertAssetDialog;
