import React, { useEffect, useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useFetchAllClients, useFetchVerifiedClients } from 'store/reducers/clients';
import Table from 'components/Table/Index';

const useStyles = makeStyles(() => ({
  content: {
    padding: 20,
  },
}));

const Index = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { view } = useParams();
  const history = useHistory();
  const { allClients, verifiedClients } = useSelector((state) => state.clients);
  const [, fetchAllClients] = useFetchAllClients();
  const [, fetchVerifiedClients] = useFetchVerifiedClients();

  let clients = [],
    fetched = false,
    fetchClients;

  if (view === 'all') {
    clients = allClients.data;
    fetched = allClients.fetched;
    fetchClients = fetchAllClients;
  } else if (view === 'verified') {
    clients = verifiedClients.data;
    fetched = verifiedClients.fetched;
    fetchClients = fetchVerifiedClients;
  }

  useEffect(() => {
    if (!view) {
      history.replace({ pathname: '/admin/clients/all' });
    }
  }, [view, history]);

  useEffect(() => {
    if (!fetched && fetchClients) {
      fetchClients();
    }
  }, [fetched, view, fetchClients]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('email'),
        accessor: 'email',
      },
      {
        Header: t('phoneNumber'),
        accessor: 'phoneNumber',
      },
    ],
    [t]
  );

  const data = useMemo(
    () =>
      clients?.map((client) => ({
        id: client.id,
        name: client.fullName,
        email: client.email,
        phoneNumber: client.phoneNumber,
        linkPath: `/admin/clients/${client.id}`,
      })),
    [clients]
  );

  return (
    <>
      <AppBar position="static">
        <Tabs value={view ?? 'all'}>
          <Tab label={t('allUsers')} component={Link} to="/admin/clients/all" value="all" />
          <Tab label={t('verifiedUsers')} component={Link} to="/admin/clients/verified" value="verified" />
        </Tabs>
      </AppBar>
      {!fetched ? (
        <LinearProgress />
      ) : (
        <div className={classes.content}>
          <Table columns={columns} data={data} />
        </div>
      )}
    </>
  );
};

export default Index;
