import { createSlice } from '@reduxjs/toolkit';
import useAsyncFn from 'hooks/useAsyncFn';
import { useDispatch } from 'react-redux';
import { fetchAssetAsync, fetchAssetsAsync, createAssetAsync, updateAssetAsync } from 'transfer/admin/assetsApi';

const assetsSlice = createSlice({
  name: 'assets',
  initialState: {
    fetched: false,
    data: [],
  },
  reducers: {
    addAssets(state, { payload }) {
      state.data = payload;
      state.fetched = true;
    },
    addAsset(state, { payload }) {
      state.data.push(payload);
    },
    updateAsset(state, { payload }) {
      const assetIndex = state.data.findIndex((asset) => asset.id === payload.id);
      if (assetIndex >= 0) {
        state.data[assetIndex] = payload;
      }
    },
  },
});

export const { addAssets, addAsset, updateAsset } = assetsSlice.actions;

export default assetsSlice.reducer;

export function useFetchAssets() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const assets = await fetchAssetsAsync();
    dispatch(addAssets(assets));
    return assets;
  }, [dispatch]);
}

export function useFetchAsset() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id) => {
      return await fetchAssetAsync(id);
    },
    [dispatch]
  );
}

export function useCreateAsset() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const asset = await createAssetAsync(data);
      if (asset) {
        dispatch(addAsset(asset));
      }
      return asset;
    },
    [dispatch]
  );
}

export function useUpdateAsset() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (assetId, data) => {
      const asset = await updateAssetAsync(assetId, data);
      if (asset) {
        dispatch(updateAsset(asset));
      }
      return asset;
    },
    [dispatch]
  );
}
