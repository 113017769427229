import axios from 'axios';

import { apiEndpoints } from 'transfer/config';

const assetsEndpoints = apiEndpoints.admin.assets;

export async function fetchAssetsAsync() {
  const res = await axios.get(`${assetsEndpoints}`);
  return res.data;
}

export async function fetchAssetAsync(id) {
  const res = await axios.get(`${assetsEndpoints}/${id}`);
  return res.data;
}

export async function createAssetAsync(data) {
  try {
    const res = await axios.post(`${assetsEndpoints}`, data);
    return res.data;
  } catch {
    return false;
  }
}

export async function updateAssetAsync(id, data) {
  try {
    const res = await axios.put(`${assetsEndpoints}/${id}`, data);
    return res.data;
  } catch {
    return false;
  }
}
