import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, CssBaseline } from '@material-ui/core';

import theme from './theme';
import useStyles from './styles';
import SideDrawer from './components/SideDrawer';
import SnackbarProvider from './components/SnackbarProvider';

const Index = ({ children, signedIn }) => {
  const classes = useStyles(theme)();
  console.log(theme);
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <CssBaseline />
        <SideDrawer signedIn={signedIn} />
        <main className={classes.main}>{children}</main>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

Index.propTypes = {
  children: PropTypes.node.isRequired,
  signedIn: PropTypes.bool.isRequired,
};

export default Index;
