import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    margin: 10,
  },
}));

const ErrorMessage = ({ error }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(['translation', 'components']);

  return (
    <div className={classes.root}>
      <Typography color="error">{t('components:ErrorMessage.title')}</Typography>
      {i18n.exists(`translation:server-error-messages.${error?.response?.data?.type}`) && (
        <div>
          {t('components:ErrorMessage.message')}:{' '}
          {t(`translation:server-error-messages.${error?.response?.data?.type}`)}
        </div>
      )}
      {error?.response?.data?.type && (
        <div>
          {t('components:ErrorMessage.type')}: {error?.response?.data?.type}
        </div>
      )}
      {error?.response?.data?.traceId && (
        <div>
          {t('components:ErrorMessage.traceId')}: {error?.response?.data?.traceId}
        </div>
      )}
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.object.isRequired,
};

export default ErrorMessage;
