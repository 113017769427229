import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import useAsyncFn from 'hooks/useAsyncFn';
import {
  approveWireTransferDepositAsync,
  completeWireTransferWithdrawalAsync,
  failWireTransferAsync,
  fetchPendingWireTransactionsAsync,
  fetchTransactionsAsync,
  setWireTransferPendingAsync,
} from 'transfer/admin/transactionsApi';

const initialState = {
  transactions: undefined,
  pendingWireTransactions: undefined,
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addTransactions(state, { payload }) {
      state.transactions = payload;
    },
    addPendingWireTransactions(state, { payload }) {
      state.pendingWireTransactions = payload;
    },
    addPendingWireTransaction(state, { payload }) {
      if (!state.pendingWireTransactions) return;
      state.pendingWireTransactions = [...state.pendingWireTransactions, payload].sort(
        (a, b) => -a.createdAt.localeCompare(b.createdAt)
      );
    },
    updateTransaction(state, { payload }) {
      if (state.transactions) {
        const indexOfItem = state.transactions.findIndex((t) => t.id === payload?.id);
        if (indexOfItem >= 0) {
          state.transactions[indexOfItem] = payload;
        }
      }
      if (state.pendingWireTransactions) {
        const indexOfItem = state.pendingWireTransactions.findIndex((t) => t.id === payload?.id);
        if (indexOfItem >= 0) {
          if (payload?.status !== 'Pending') {
            state.pendingWireTransactions.splice(indexOfItem, 1);
          } else {
            state.pendingWireTransactions[indexOfItem] = payload;
          }
        }
      }
    },
  },
});

export const {
  addTransactions,
  addPendingWireTransactions,
  addPendingWireTransaction,
  updateTransaction,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;

export function useFetchTransactions() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const transactions = await fetchTransactionsAsync();
    dispatch(addTransactions(transactions.list));
    return transactions;
  }, [dispatch]);
}

export function useFetchPendingWireTransactions() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const transactions = await fetchPendingWireTransactionsAsync();
    dispatch(addPendingWireTransactions(transactions.list));
    return transactions;
  }, [dispatch]);
}

export function useApproveWireTransferDeposit() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id, approvedAmount, internalComment, notifyUser) => {
      const transaction = await approveWireTransferDepositAsync(id, approvedAmount, internalComment, notifyUser);
      dispatch(updateTransaction(transaction));
      return transaction;
    },
    [dispatch]
  );
}

export function useCompleteWireTransferWithdrawal() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id, internalComment, notifyUser) => {
      const transaction = await completeWireTransferWithdrawalAsync(id, internalComment, notifyUser);
      dispatch(updateTransaction(transaction));
      return transaction;
    },
    [dispatch]
  );
}

export function useFailWireTransfer() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id, rejectionComment, internalComment, notifyUser) => {
      const transaction = await failWireTransferAsync(id, rejectionComment, internalComment, notifyUser);
      dispatch(updateTransaction(transaction));
      return transaction;
    },
    [dispatch]
  );
}

export function useSetWireTransferPending() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id, internalComment) => {
      const transaction = await setWireTransferPendingAsync(id, internalComment);
      dispatch(updateTransaction(transaction));
      dispatch(addPendingWireTransaction(transaction));
      return transaction;
    },
    [dispatch]
  );
}
