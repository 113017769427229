import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { useSetWireTransferPending } from 'store/reducers/transactions';
import LoaderWrapper from 'components/LoaderWrapper';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 16,
    height: '100%',
    '& > div:not(:last-child)': {
      paddingBottom: 16,
    },
  },
  action: {
    flexGrow: 1,
  },
}));

const SetPendingTransactionForm = ({ transactionId, previousInternalComment, updateTransaction }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ loading }, setWireTransferPending] = useSetWireTransferPending();
  const schema = useMemo(
    () =>
      yup.object().shape({
        internalComment: yup.string(),
      }),
    []
  );

  const { register, errors, handleSubmit, formState } = useForm({
    defaultValues: {
      internalComment: previousInternalComment,
    },
    resolver: yupResolver(schema),
  });
  const { isSubmitting } = formState;
  const disabled = isSubmitting || loading;

  const onSubmit = async (data) => {
    const transaction = await setWireTransferPending(transactionId, data.internalComment);
    updateTransaction(transaction);
  };

  return (
    <>
      <Grid container component="form" direction="column" onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Grid item>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="internalComment"
            label={t('internal-comment')}
            multiline
            rows={5}
            type="text"
            inputRef={register}
            error={Boolean(errors.internalComment)}
            helperText={errors.internalComment?.message}
          />
        </Grid>
        <Grid container justify="flex-end" alignItems="flex-end" className={classes.action}>
          <LoaderWrapper loading={disabled}>
            <Button
              variant="contained"
              color="primary"
              disabled={false}
              type="submit"
              fullWidth={disabled}
              size="small"
            >
              {t('set-pending')}
            </Button>
          </LoaderWrapper>
        </Grid>
      </Grid>
    </>
  );
};

SetPendingTransactionForm.propTypes = {
  updateTransaction: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
};

SetPendingTransactionForm.defaultProps = {
  editData: null,
};

export default SetPendingTransactionForm;
