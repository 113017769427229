import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from 'components/Table/Index';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import { useCreateFulfillmentOrder, useFetchFulfillmentOrders } from 'store/reducers/fulfillmentOrders';
import { formatISODateString } from 'utils/helpers';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  table: {
    marginTop: 10,
  },
}));

const AssetsIndex = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ loading }, fetchFulfillmentOrders] = useFetchFulfillmentOrders();
  const [{ loading: creatingFulfillmentOrder }, createFulfillmentOrder] = useCreateFulfillmentOrder();
  const { fetched, data: fulfillmentOrders } = useSelector((state) => state.fulfillmentOrders);
  const [activeExists, setActiveExists] = useState(true);
  const disabled = loading || creatingFulfillmentOrder;

  useEffect(() => {
    if (!fetched) {
      fetchFulfillmentOrders();
    } else {
      if (fulfillmentOrders.length > 0 && !fulfillmentOrders[0].fulfilledAt) {
        setActiveExists(true);
      } else {
        setActiveExists(false);
      }
    }

    console.log(fulfillmentOrders);
  }, [fetched, fetchFulfillmentOrders, fulfillmentOrders]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('create'),
        accessor: 'createdAt',
        Cell: (x) => {
          return formatISODateString(x.value);
        },
      },
      {
        Header: t('internally-netted'),
        accessor: 'internallyNettedAt',
        Cell: (x) => {
          return formatISODateString(x.value);
        },
      },
      {
        Header: t('fulfilled'),
        accessor: 'fulfilledAt',
        Cell: (x) => {
          return formatISODateString(x.value);
        },
      },
    ],
    [t]
  );

  const data = React.useMemo(
    () =>
      fetched &&
      fulfillmentOrders.map((fulfillmentOrder) => ({
        ...fulfillmentOrder,
        linkPath: `/admin/fulfillment-orders/${fulfillmentOrder.id}`,
      })),
    [fetched, fulfillmentOrders]
  );

  return (
    <div className={classes.root}>
      <Button
        onClick={createFulfillmentOrder}
        startIcon={<AddIcon />}
        fullWidth={false}
        size="small"
        disabled={activeExists || disabled}
      >
        {t('create-fulfillment-order')}
      </Button>
      {!fetched || loading ? (
        <LinearProgress />
      ) : (
        <>
          <div className={classes.table}>{<Table data={data} columns={columns} />}</div>
        </>
      )}
    </div>
  );
};
export default AssetsIndex;
