import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import useAsyncFn from 'hooks/useAsyncFn';
import { disableCountryAsync, enableCountryAsync, fetchAllCountriesAsync } from 'transfer/admin/countryApi';

const countriesSlice = createSlice({
  name: 'countries',
  initialState: {
    fetched: false,
    data: [],
  },
  reducers: {
    addCountries(state, { payload }) {
      return {
        ...state,
        fetched: true,
        data: payload,
      };
    },
    enableCountryEnabled(state, { payload }) {
      const country = state.data.find((country) => country.id === payload);
      if (country) {
        country.enabled = !country.enabled;
      }
    },
  },
});

export const { addCountries, enableCountryEnabled } = countriesSlice.actions;

export default countriesSlice.reducer;

export function useFetchCountries() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const countries = await fetchAllCountriesAsync();
    if (countries) {
      dispatch(addCountries(countries));
    }
    return countries;
  }, [dispatch]);
}

export function useToggleCountry() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (enabled, countryId) => {
      let updateSuccessful;
      dispatch(enableCountryEnabled(countryId));
      if (enabled) {
        updateSuccessful = await disableCountryAsync(countryId);
      } else {
        updateSuccessful = await enableCountryAsync(countryId);
      }
      if (!updateSuccessful) {
        const countries = await fetchAllCountriesAsync();
        dispatch(addCountries(countries));
      }
    },
    [dispatch]
  );
}
