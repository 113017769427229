import axios from 'axios';

import { apiEndpoints } from 'transfer/config';

const fulfillmentOrdersEndpoints = apiEndpoints.admin.fulfillmentOrders;

export async function fetchFulfillmentOrdersAsync() {
  const res = await axios.get(`${fulfillmentOrdersEndpoints}?pageSize=999`);
  return res.data;
}

export async function fetchFulfillmentOrderAsync(id) {
  const res = await axios.get(`${fulfillmentOrdersEndpoints}/${id}`);
  return res.data;
}

export async function createFulfillmentOrderAsync() {
  try {
    const res = await axios.post(`${fulfillmentOrdersEndpoints}`);
    return res.data;
  } catch (err) {
    throw err;
  }
}

export async function netFulfillmentOrderAsync(id) {
  try {
    const res = await axios.post(`${fulfillmentOrdersEndpoints}/${id}/net`);
    return res.data;
  } catch (err) {
    throw err;
  }
}

export async function completeFulfillmentOrderAsync(id, data) {
  try {
    const res = await axios.post(`${fulfillmentOrdersEndpoints}/${id}/fulfill`, data);
    return res.data;
  } catch (err) {
    throw err;
  }
}

export async function getFulfillmentExcelTrades(id) {
  try {
    const res = await axios.get(`${fulfillmentOrdersEndpoints}/${id}/trades/excel`);
    return res.data;
  } catch (err) {
    throw err;
  }
}
