import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRoute = ({ component, ...rest }) => {
  const {
    authenticated,
    fetched,
    data: { roles },
  } = useSelector((state) => state.user);
  if (!fetched) return null;

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={
        authenticated && roles.includes('Admin')
          ? component
          : ({ location }) => (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
                from={location.pathname}
              />
            )
      }
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default AuthRoute;
