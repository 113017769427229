import axios from 'axios';

import { apiEndpoints } from 'transfer/config';

const feesEndpoints = apiEndpoints.admin.fees;

export async function createManagementFeeAsync() {
  const res = await axios.post(`${feesEndpoints}/management`);
  return res.data;
}

// Todo: fix pagination later
export async function fetchManagementPortfolioFeesAsync(portfolioId, pageSize = 999) {
  const res = await axios.get(`${feesEndpoints}/management/portfolio-orders/${portfolioId}/transactions`, {
    params: {
      pageSize,
    },
  });
  return res.data;
}

// Todo: fix pagination later
export async function fetchManagementFeesAsync(pageSize = 999) {
  const res = await axios.get(`${feesEndpoints}/management/portfolio-orders`, {
    params: {
      pageSize,
    },
  });
  return res.data;
}
