import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
    root: {
        padding: 20,
    },
    list: {
        maxWidth: 500,
        paddingTop: 4,
        marginBottom: 15,
        '& > li': {
            padding: '5px 0px',
            justifyContent: 'space-between',
        },
    },
}));

const Overview = ({ asset }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(()=>{
        console.log(asset)
    })

    const items = [
        { label: t('marketTickerSymbol'), value: asset.marketTickerSymbol },
        { label: t('name'), value: asset.name },
        { label: t('isin'), value: asset.isin },
        { label: t('color'), value: asset.color },
    ];

    return (
        <Grid container className={classes.root}>
            <Grid item xs={6}>
                <Typography variant="subtitle2">{t('general')}</Typography>
                <List className={classes.list}>
                    {items.map((item) => (
                        <ListItem key={item.label}>
                            <div>{item.label}</div>
                            <div>{item.value}</div>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    );
};

Overview.propTypes = {
    asset: PropTypes.object.isRequired,
};

export default Overview;
