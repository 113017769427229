import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import useAsyncFn from 'hooks/useAsyncFn';
import { fetchUserOnfidoChecksAsync } from 'transfer/admin/onfidoApi';
import { addVerifiedClientRoleAsync, fetchUserAsync, removeUserRoleAsync } from 'transfer/admin/users';
import { roles } from 'utils/enums';

import Overview from './show/Overview';
import IdentityVerification from './show/IdentityVerification';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    fontWeight: 300,
  },
  content: {
    margin: 10,
    display: 'flex',
  },
  contentError: {
    padding: 20,
  },
}));

const Show = () => {
  const { t } = useTranslation();
  const { id, view } = useParams();
  const history = useHistory();
  const classes = useStyles();

  // Stores user data if user was found, is undefined if not fetched yet and is false if user wasn't found
  const [user, setUser] = useState(undefined);
  const [disabled, setDisabled] = useState(false);
  const [onfidoChecksState, fetchUserOnfidoChecks] = useAsyncFn(fetchUserOnfidoChecksAsync);

  const fetchAndUpdateUserWithFunc = useCallback(
    async (func) => {
      if (disabled) return;
      setDisabled(true);
      if (func) await func();
      const user = await fetchUserAsync(id);
      setUser(user);
      setDisabled(false);
    },
    [id, disabled]
  );

  const handleAddVerifiedRole = async () => {
    await fetchAndUpdateUserWithFunc(() => addVerifiedClientRoleAsync(id));
  };

  const handleRemoveVerifiedRole = async () => {
    await fetchAndUpdateUserWithFunc(() => removeUserRoleAsync(id, roles.VerifiedUser));
  };

  useEffect(() => {
    if (!view) {
      history.replace({ pathname: `/admin/clients/${id}/overview` });
    }
  }, [view, history, id]);

  useEffect(() => {
    if (user === undefined && id) {
      fetchAndUpdateUserWithFunc().catch(() => {
        history.replace({ pathname: `/admin/clients/` });
      });
    }
  }, [user, id, fetchAndUpdateUserWithFunc, history]);

  useEffect(() => {
    setUser(undefined);
  }, [id]);

  if (user === false) {
    return <div className={classes.contentError}>{`No user found with the following id: ${id}`}</div>;
  }

  if (!user) {
    return <LinearProgress />;
  }

  const renderView = () => {
    switch (view) {
      case 'overview':
        return (
          <Overview
            user={user}
            disabled={disabled}
            removeVerifiedClientRole={handleRemoveVerifiedRole}
            addVerifiedClientRole={handleAddVerifiedRole}
          />
        );
      case 'identity-verification':
        return (
          <IdentityVerification
            userOnfidoData={user.client.onfidoVerification}
            userId={id}
            fetchUserOnfidoChecks={fetchUserOnfidoChecks}
            onfidoChecksState={onfidoChecksState}
          />
        );
      default:
        return 'TODO';
    }
  };
  return (
    <>
      <div className={classes.header}>
        <Typography variant="h3">User profile for {user.fullName}</Typography>
        {user.roles.includes('VerifiedClient') && (
          <Tooltip title={t('verifiedClient')} placement="left">
            <VerifiedUserIcon />
          </Tooltip>
        )}
      </div>
      <AppBar position="static">
        <Tabs value={view ?? 'overview'} variant="scrollable">
          <Tab
            disabled={disabled}
            label={t('overview')}
            component={Link}
            to={`/admin/clients/${id}/overview`}
            value="overview"
          />
          <Tab
            disabled={disabled}
            label={t('transactions')}
            component={Link}
            to={`/admin/clients/${id}/transactions`}
            value="transactions"
          />
          <Tab
            disabled={disabled}
            label={t('plans')}
            component={Link}
            to={`/admin/clients/${id}/plans`}
            value="plans"
          />
          <Tab
            disabled={disabled}
            label={t('messages')}
            component={Link}
            to={`/admin/clients/${id}/messages`}
            value="messages"
          />
          <Tab
            disabled={disabled}
            label={t('identityVerification')}
            component={Link}
            to={`/admin/clients/${id}/identity-verification`}
            value="identity-verification"
          />
        </Tabs>
      </AppBar>
      <Paper className={classes.content}>{renderView()}</Paper>
      <div />
    </>
  );
};

export default Show;
