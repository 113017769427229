import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, List, ListItem, ListItemIcon, Drawer, makeStyles } from '@material-ui/core';
import ClientsIcon from '@material-ui/icons/People';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import CountriesIcon from '@material-ui/icons/Public';
import CurrencyIcon from '@material-ui/icons/MonetizationOn';
import PaymentIcon from '@material-ui/icons/Payment';
import PortfolioIcon from '@material-ui/icons/BusinessCenter';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { ReactComponent as FeeIcon } from 'assets/icons/RequestQuote.svg';
import { ReactComponent as CocoaLogo } from 'assets/icons/CocoaLargeLogo.svg';
import { useLogoutUser } from 'store/reducers/user';
import { SIDE_DRAWER_WIDTH } from 'utils/constants';
import useArrayToggle from 'hooks/useArrayToggle';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import { InsertDriveFile, LocalShipping } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    maxWidth: SIDE_DRAWER_WIDTH,
    width: '80vw',
  },
  backdrop: {
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  rootMenu: {
    height: '100%',
    marginBottom: 20,
  },
  logo: {
    margin: '50px 0',
  },
  list: {
    borderTop: '1px solid white',
    color: 'white',
    padding: 0,
  },
  listItem: {
    height: 52,
    borderBottom: '1px solid white',
    '& span': {
      ...theme.typography.body1,
      fontWeight: 500,
    },
  },
  nestedList: {
    borderBottom: '1px solid white',
    '& > a:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
  },
  listItemNested: {
    paddingLeft: 15,
    '& span': {
      ...theme.typography.body1,
      fontWeight: 400,
    },
    '& svg': {
      marginRight: 8,
      height: 14,
    },
  },
  listItemSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.3) !important',
  },
  listItemIcon: {
    '& svg': {
      fill: 'white',
    },
  },
}));

const SideDrawer = ({ signedIn }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { values, toggleValue } = useArrayToggle();
  const [{ loading: loggingOutUser }, logoutUser] = useLogoutUser();

  const handleLogoutUser = async () => {
    const userLoggedOut = await logoutUser();
    if (userLoggedOut) {
      history.push('/');
    }
  };

  const items = [
    {
      icon: <ClientsIcon className={classes.listItemIcon} />,
      title: t('clients'),
      type: 'link',
      path: '/admin/clients',
      selectedPath: '/admin/clients',
    },
    {
      icon: <PortfolioIcon className={classes.listItemIcon} />,
      title: t('portfolios'),
      type: 'link',
      path: '/admin/portfolios',
      selectedPath: '/admin/portfolios',
    },
    {
      icon: <PaymentIcon className={classes.listItemIcon} />,
      title: t('transactions'),
      type: 'collapse',
      children: [
        {
          title: t('wire'),
          type: 'link',
          path: '/admin/transactions/wire/all',
          selectedPath: '/admin/transactions/wire',
        },
      ],
    },
    {
      icon: <FeeIcon className={classes.listItemIcon} />,
      title: t('management-fees'),
      type: 'link',
      path: '/admin/management-fees',
      selectedPath: '/admin/management-fees',
    },
    {
      icon: <CountriesIcon className={classes.listItemIcon} />,
      title: t('countries'),
      type: 'link',
      path: '/admin/countries',
      selectedPath: '/admin/countries',
    },
    {
      icon: <CurrencyIcon className={classes.listItemIcon} />,
      title: t('currencies'),
      type: 'link',
      path: '/admin/currencies',
      selectedPath: '/admin/currencies',
    },
    {
      icon: <InsertDriveFile className={classes.listItemIcon} />,
      title: t('assets'),
      type: 'link',
      path: '/admin/assets',
      selectedPath: '/admin/assets',
    },
    {
      icon: <LocalShipping className={classes.listItemIcon} />,
      title: t('fulfillment-orders'),
      type: 'link',
      path: '/admin/fulfillment-orders',
      selectedPath: '/admin/fulfillment-orders',
    },
  ];

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      open
      classes={{
        paper: classes.paper,
      }}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <Grid container alignItems="flex-start" justify="center">
        <Grid item xs={8} container justify="center" className={classes.logo}>
          <CocoaLogo />
        </Grid>
      </Grid>
      <Grid container className={classes.rootMenu}>
        <Grid item xs={12} container justify="space-between" direction="column">
          <List className={classes.list}>
            {signedIn &&
              items.map((item, index) => {
                switch (item.type) {
                  case 'link':
                    return (
                      <ListItem
                        key={item.title}
                        button
                        selected={pathname.startsWith(item.selectedPath)}
                        className={classes.listItem}
                        component={Link}
                        to={item.path}
                        disabled={loggingOutUser}
                        classes={{
                          selected: classes.listItemSelected,
                        }}
                      >
                        <ListItemIcon className={classes.listItemIcon}>{item.icon && item.icon}</ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItem>
                    );
                  case 'func': {
                    return (
                      <ListItem
                        key={item.title}
                        button
                        className={classes.listItem}
                        onClick={async () => {
                          if (item.type === 'func') await item.func();
                        }}
                        disabled={loggingOutUser}
                      >
                        <ListItemIcon className={classes.listItemIcon}>{item.icon && item.icon}</ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItem>
                    );
                  }
                  case 'collapse': {
                    return (
                      <>
                        <ListItem
                          key={item.title}
                          button
                          className={classes.listItem}
                          onClick={() => toggleValue(index)}
                          disabled={loggingOutUser}
                        >
                          <ListItemIcon className={classes.listItemIcon}>{item.icon && item.icon}</ListItemIcon>
                          <ListItemText primary={item.title} />
                          {values.includes(index) ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={values.includes(index)} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding className={classes.nestedList}>
                            {item.children.map((child) => (
                              <ListItem
                                key={child.title}
                                button
                                selected={pathname.startsWith(child.selectedPath)}
                                className={classes.listItemNested}
                                component={Link}
                                to={child.path}
                                disabled={loggingOutUser}
                                classes={{
                                  selected: classes.listItemSelected,
                                }}
                              >
                                <ArrowRightIcon />
                                <ListItemText primary={child.title} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    );
                  }
                  default:
                    return null;
                }
              })}
          </List>
          {signedIn && (
            <List className={classes.list}>
              <ListItem
                button
                className={classes.listItem}
                onClick={async () => {
                  await handleLogoutUser();
                }}
                disabled={loggingOutUser}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <SignOutIcon />
                </ListItemIcon>
                <ListItemText primary={t('signOut')} />
              </ListItem>
            </List>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

SideDrawer.propTypes = {
  signedIn: PropTypes.bool.isRequired,
};

export default SideDrawer;
