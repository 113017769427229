import axios from 'axios';

import { apiEndpoints } from 'transfer/config';

const portfoliosEndpoints = apiEndpoints.admin.portfolios;

export async function fetchPortfoliosAsync() {
  const res = await axios.get(`${portfoliosEndpoints}`);
  return res.data;
}

export async function fetchPortfolioAsync(id) {
  const res = await axios.get(`${portfoliosEndpoints}/${id}`);
  return res.data;
}

// Todo: fix pagination later
export async function fetchPortfolioPlansAsync(id, pageSize = 999) {
  const res = await axios.get(`${portfoliosEndpoints}/${id}/plans`, {
    params: {
      pageSize,
    },
  });
  return res.data;
}

export async function updatePortfolioHoldings(data) {
  const res = await axios.post(`${portfoliosEndpoints}/holdings`, data);
  return res.data;
}

export async function updatePortfolioHoldingPrices(data) {
  const res = await axios.post(`${portfoliosEndpoints}/holdings/prices`, data);
  return res.data;
}
