import React from 'react';
import {Switch, Route} from 'react-router-dom';

import AuthRoute from 'components/AuthRoute';

import Home from 'views/Home';
import NotFound from 'views/NotFound';
import PasswordReset from 'views/PasswordReset';

import Currencies from 'views/admin/Currencies';
import Countries from 'views/admin/Countries';
import ClientsIndex from 'views/admin/clients/Index';
import ClientsShow from 'views/admin/clients/Show';
import WireIndex from 'views/admin/transactions/WireIndex';
import FeeIndex from 'views/admin/fees/Index';
import PortfoliosIndex from 'views/admin/portfolios/Index';
import PortfoliosShow from 'views/admin/portfolios/Show';
import AssetsIndex from 'views/admin/assets/Index';
import AssetsShow from 'views/admin/assets/Show';
import FulfillmentOrdersIndex from 'views/admin/fulfillmentOrders/Index';
import FulfillmentOrdersShow from 'views/admin/fulfillmentOrders/Show/'

const Routes = () => {
    return (
        <Switch>
            <AuthRoute exact path="/admin/currencies" component={() => <Currencies/>}/>
            <AuthRoute exact path="/admin/countries/:view(all|enabled|disabled)?" component={() => <Countries/>}/>
            <AuthRoute exact path="/admin/clients/:view(all|verified)?" component={() => <ClientsIndex/>}/>
            <AuthRoute exact path="/admin/clients/:id/:view?" component={() => <ClientsShow/>}/>
            <AuthRoute exact path="/admin/transactions/wire/:view(all|pending)?" component={() => <WireIndex/>}/>
            <AuthRoute exact path="/admin/management-fees" component={() => <FeeIndex/>}/>
            <AuthRoute exact path="/admin/portfolios" component={() => <PortfoliosIndex/>}/>
            <AuthRoute exact path="/admin/portfolios/:id/:view?" component={() => <PortfoliosShow/>}/>
            <AuthRoute exact path="/admin/assets" component={() => <AssetsIndex/>}/>
            <AuthRoute exact path="/admin/assets/:id/:view?" component={() => <AssetsShow/>}/>
            <AuthRoute exact path="/admin/fulfillment-orders" component={() => <FulfillmentOrdersIndex/>}/>
            <AuthRoute exact path="/admin/fulfillment-orders/:id/:view?" component={() => <FulfillmentOrdersShow/>}/>
            <Route exact path="/" component={() => <Home/>}/>
            <Route exact path="/password-reset/" component={() => <PasswordReset/>}/>
            <Route path="*" component={() => <NotFound/>}/>
        </Switch>
    );
};

export default Routes;
