import axios from 'axios';

import { apiEndpoints } from 'transfer/config';

const userEndpoints = apiEndpoints.admin.users;

export async function fetchUsersAsync(role) {
  const res = await axios.get(`${userEndpoints}`, {
    params: {
      role,
      pageSize: 999,
    },
  });
  return res.data;
}

export async function fetchUserAsync(id) {
  const res = await axios.get(`${userEndpoints}/${id}`);
  return res.data;
}

export async function addUserRoleAsync(id, role) {
  const res = await axios.post(`${userEndpoints}/roles/add/${id}?role=${role}`);
  return res.data;
}

export async function addVerifiedClientRoleAsync(id) {
  const res = await axios.post(`${userEndpoints}/roles/add/verified-client/${id}`);
  return res.data;
}

export async function removeUserRoleAsync(id, role) {
  const res = await axios.post(`${userEndpoints}/roles/delete/${id}?role=${role}`);
  return res.data;
}
