import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

export default createMuiTheme({
  palette,
  typography,
  overrides,
  mixins: {
    toolbar: {
      minHeight: 40,
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
    },
    MuiTextField: {
      fullWidth: true,
    },
    MuiAccordionSummary: {
      disableRipple: false,
    },
    MuiPaper: {
      square: true,
    },
    MuiTooltip: {
      arrow: true,
    },
  },
});
