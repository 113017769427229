import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import { useTranslation } from 'react-i18next';
import ErrorMessage from 'components/ErrorMessage';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  list: {
    maxWidth: 300,
  },
  clearIcon: {
    fill: 'red',
  },
  doneIcon: {
    fill: 'green',
  },
  content: {
    margin: 10,
    '& button, a': {
      marginTop: 10,
      marginRight: 10,
    },
  },
  onfidoDashboardContainer: {
    width: '100%',
    height: 'calc(100vh - 128px)',
  },
}));

const IdentityVerification = ({
  userOnfidoData,
  userId,
  fetchUserOnfidoChecks,
  onfidoChecksState: { loading: fetchingOnfidoChecks, value: onfidoChecks, error: onfidoChecksError },
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['translation', 'views']);
  const { documentReportUploaded, facialSimilarityPhotoUploaded, proofOfAddressUploaded } = userOnfidoData ?? {};

  useEffect(() => {
    if (!onfidoChecks && !onfidoChecksError) {
      fetchUserOnfidoChecks(userId);
    }
  }, [onfidoChecks, onfidoChecksError, userId, fetchUserOnfidoChecks]);

  if (
    onfidoChecks?.length === 0 ||
    !documentReportUploaded ||
    !facialSimilarityPhotoUploaded ||
    !proofOfAddressUploaded
  ) {
    return (
      <div className={classes.root}>
        {t(
          `views:admin.clients.show.identity-verification.${
            onfidoChecks?.length === 0 ? 'no-check' : 'missing-documents'
          }`
        )}
        <List className={classes.list}>
          <ListItem divider>
            {t('translation:identity-verification-documents.document')}
            <ListItemSecondaryAction>
              {documentReportUploaded ? (
                <DoneIcon className={classes.doneIcon} />
              ) : (
                <ClearIcon className={classes.clearIcon} />
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem divider>
            {t('translation:identity-verification-documents.facial')}
            <ListItemSecondaryAction>
              {facialSimilarityPhotoUploaded ? (
                <DoneIcon className={classes.doneIcon} />
              ) : (
                <ClearIcon className={classes.clearIcon} />
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem divider>
            {t('translation:identity-verification-documents.poa')}
            <ListItemSecondaryAction>
              {proofOfAddressUploaded ? (
                <DoneIcon className={classes.doneIcon} />
              ) : (
                <ClearIcon className={classes.clearIcon} />
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    );
  }

  if (onfidoChecksError) {
    return <ErrorMessage error={onfidoChecksError} />;
  }
  const openResultInNewWindow = (checkResultUrl) => {
    window.open(
      checkResultUrl,
      'test',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`
    );
  };

  return (
    <div className={classes.onfidoDashboardContainer}>
      {fetchingOnfidoChecks ? (
        <LinearProgress />
      ) : onfidoChecks?.length === 0 ? (
        t('views:admin.clients.show.identity-verification.no-check-found')
      ) : (
        onfidoChecks && (
          <>
            <div className={classes.content}>
              <Typography>{t('views:admin.clients.show.identity-verification.iframe-not-working-message')}</Typography>
              <Button size="small" fullWidth={false} onClick={() => openResultInNewWindow(onfidoChecks[0].resultsUri)}>
                {t('open-in-new-window')}
              </Button>
              <Button size="small" fullWidth={false} href={onfidoChecks[0].resultsUri} target="_blank">
                {t('open-in-new-tab')}
              </Button>
            </div>
            <iframe
              frameBorder="none"
              title="Onfido dashboard"
              src={onfidoChecks[0].resultsUri}
              width="100%"
              height="100%"
            />
          </>
        )
      )}
    </div>
  );
};

IdentityVerification.propTypes = {
  userOnfidoData: PropTypes.object,
  userId: PropTypes.string.isRequired,
  fetchUserOnfidoChecks: PropTypes.func.isRequired,
  onfidoChecksState: PropTypes.shape({
    loading: PropTypes.bool,
    value: PropTypes.array,
    error: PropTypes.object,
  }).isRequired,
};

IdentityVerification.defaultProps = {
  userOnfidoData: null,
};

export default IdentityVerification;
