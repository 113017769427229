import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Switch, Route, useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useFetchPortfolio, useFetchPortfolioPlans } from 'store/reducers/portfolios';
import { useFetchPortfolioManagementFees } from 'store/reducers/fees';
import Overview from './show/Overview';
import Plans from './show/Plans';
import ManagementFees from './show/ManagementFees';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    fontWeight: 300,
  },
  content: {
    margin: 10,
    display: 'flex',
  },
  contentError: {
    padding: 20,
  },
}));

const PortfoliosShow = () => {
  const classes = useStyles();
  const { id, view } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [portfolio, setPortfolio] = useState();
  const [managementFees, setManagementFees] = useState();
  const [plans, setPlans] = useState();
  const [, fetchPortfolio] = useFetchPortfolio();
  const [, fetchPortfolioPlans] = useFetchPortfolioPlans();
  const [, fetchPortfolioFees] = useFetchPortfolioManagementFees();

  useEffect(() => {
    if (id) {
      fetchPortfolio(id)
        .then((res) => setPortfolio(res))
        .catch(() => {
          history.replace({ pathname: `/admin/portfolios` });
        });
    }
  }, [id, fetchPortfolio, history]);

  useEffect(() => {
    if (!view) {
      history.replace({ pathname: `/admin/portfolios/${id}/overview` });
    }
  }, [view, history, id]);

  useEffect(() => {
    if (view && id) {
      if (view === 'plans' && !plans) {
        fetchPortfolioPlans(id)
          .then((res) => setPlans(res))
          .catch(() => setPlans([]));
      } else if (view === 'management-fees') {
        fetchPortfolioFees(id)
          .then((res) => setManagementFees(res))
          .catch(() => setManagementFees([]));
      }
    }
  }, [view, id, plans, fetchPortfolioPlans, fetchPortfolioFees]);

  if (!portfolio) return <LinearProgress />;

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h3">
          {t('portfolio')}: {t(portfolio.name)}
        </Typography>
      </div>
      <AppBar position="static">
        <Tabs value={view ?? 'overview'} variant="scrollable">
          <Tab label={t('overview')} component={Link} to={`/admin/portfolios/${id}/overview`} value="overview" />
          <Tab label={t('plans')} component={Link} to={`/admin/portfolios/${id}/plans`} value="plans" />
          <Tab
            label={t('management-fees')}
            component={Link}
            to={`/admin/portfolios/${id}/management-fees`}
            value="management-fees"
          />
        </Tabs>
      </AppBar>
      <Paper className={classes.content}>
        <Switch>
          <Route exact path="/admin/portfolios/:id/overview" component={() => <Overview portfolio={portfolio} />} />
          <Route exact path="/admin/portfolios/:id/plans" component={() => <Plans plans={plans} />} />
          <Route
            exact
            path="/admin/portfolios/:id/management-fees"
            component={() => <ManagementFees fees={managementFees} />}
          />
        </Switch>
      </Paper>
      <div />
    </>
  );
};

export default PortfoliosShow;
