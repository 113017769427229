import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  TablePagination,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTable, usePagination, useSortBy } from 'react-table';

import RowActions from 'components/Table/RowActions';
import PaginationActions from './PaginationActions';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: 'white',
  },
  tableBody: {
    '& > a': {
      position: 'relative',
    },
    '& > a:hover': {
      cursor: 'pointer',
    },
    '& > :hover': {
      zIndex: 1,
      transform: 'scale(1)',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      boxShadow:
        'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);',
    },
    '& > :nth-child(even)': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      '& .actions': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      },
    },
    '& > :nth-child(odd)': {
      backgroundColor: 'rgba(239, 239, 239, 1)',
      '& .actions': {
        backgroundColor: 'rgba(239, 239, 239, 0.9)',
      },
    },
    '& :hover .actions': {
      opacity: 1,
    },
    '& td': {
      padding: 0,
      '& > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& > div': {
        position: 'relative',
        padding: 16,
      },
    },
  },
  actions: {
    display: 'flex',
    position: 'absolute',
    cursor: 'default',
    right: 0,
    top: 0,
    padding: 5,
    height: '100%',
    alignItems: 'center',
    opacity: 0,
    transition: theme.transitions.create(['opacity']),
  },
  headerCell: {
    display: 'flex',
    userSelect: 'none',
  },
  hiddenIcon: {
    visibility: 'hidden',
  },
  rowActions: {
    display: 'none',
  },
  tableFooter: {
    backgroundColor: 'white',
  },
}));

/* eslint-disable react/jsx-key,react/jsx-props-no-spreading,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
const CustomTable = ({ columns, data, actions, disabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    gotoPage(0);
  };

  return (
    <Paper square style={{ pointerEvents: disabled ? 'none' : 'all' }}>
      <Table {...getTableProps()}>
        <TableHead className={classes.tableHead}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className={classes.headerCell}>
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <KeyboardArrowDown />
                      ) : (
                        <KeyboardArrowUp />
                      )
                    ) : (
                      <KeyboardArrowDown className={classes.hiddenIcon} />
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()} className={classes.tableBody}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                key={row.id}
                {...row.getRowProps()}
                component={row.original.linkPath ? Link : 'tr'}
                to={row.original.linkPath}
              >
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      <div>
                        <span>{cell.render('Cell')}</span>
                        {cellIndex + 1 === row.cells.length && (
                          <div className={`${classes.actions} actions`} onClick={(ev) => ev.preventDefault()}>
                            <RowActions actions={actions} rowData={row.original} />
                          </div>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter className={classes.tableFooter}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { 'aria-label': t('rowsPerPage') },
                native: true,
              }}
              labelRowsPerPage={t('rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${t('of').toLowerCase()} ${
                  count !== -1 ? count : `${t('moreThan').toLowerCase()} ${to}`
                }`
              }
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={PaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};

CustomTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  actions: PropTypes.array,
  disabled: PropTypes.bool,
};

CustomTable.defaultProps = {
  actions: [],
  disabled: false,
};

export default CustomTable;
