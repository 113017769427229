import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormDialog from 'components/Table/FormDialog';
import { updatePortfolioHoldingPrices } from 'transfer/admin/portfoliosApi';
import { getYesterdayAsIsoString } from 'utils/helpers';

const UpdateHoldingDialog = ({ show, toggle }) => {
  const { t } = useTranslation(['translation', 'views']);

  const { handleSubmit, errors, reset, register, watch, formState } = useForm({
    defaultValues: {
      date: getYesterdayAsIsoString(),
    },
  });
  const isSubmitting = formState.isSubmitting;
  // Workaround since the correct values doesn't seem to be returned to the onSubmit function.
  const date = watch('date');

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [disabled, setDisabled] = useState(false);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files?.length === 0 ? undefined : event.target.files);
  };

  const clearUpload = () => {
    setDisabled(false);
    setSelectedFiles(undefined);
  };

  const handleClose = () => {
    clearUpload();
    reset();
    toggle();
  };

  const onSubmit = async () => {
    if (!selectedFiles || selectedFiles.length === 0) return;
    setDisabled(true);
    let formData = new FormData();
    formData.append('file', selectedFiles[0]);
    formData.append('date', date);
    try {
      await updatePortfolioHoldingPrices(formData);
      handleClose();
    } finally {
      setDisabled(false);
    }
  };

  return (
    <FormDialog
      open={show}
      title={t('views:portfolios.index.update-holding-prices-dialog.title')}
      description={t('views:portfolios.index.update-holding-prices-dialog.description')}
      toggle={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
      submitDisabled={!selectedFiles || isSubmitting}
      submitText={t('upload')}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={isSubmitting}
            variant="outlined"
            name="date"
            required
            label={t('date')}
            type="date"
            inputRef={register}
            error={Boolean(errors.date)}
            helperText={errors.date?.message}
          />
          <Button
            startIcon={<AddIcon />}
            fullWidth={true}
            size="small"
            component={'label'}
            disabled={disabled}
            style={{ marginTop: 20 }}
          >
            {t('uploadFile')}
            <input
              type="file"
              onChange={selectFile}
              hidden
              key={`prices-file-${selectedFiles && selectedFiles[0]?.name}`}
            />
          </Button>
          <p>{selectedFiles && (selectedFiles[0]?.name ?? '')}</p>
        </Grid>
      </Grid>
    </FormDialog>
  );
};

UpdateHoldingDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default UpdateHoldingDialog;
