import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useFetchAsset } from 'store/reducers/assets';
import Overview from './Show/Overview';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    fontWeight: 300,
  },
  content: {
    margin: 10,
    display: 'flex',
  },
  contentError: {
    padding: 20,
  },
}));

const PortfoliosShow = () => {
  const classes = useStyles();
  const { id, view } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [asset, setAsset] = useState();
  const [, fetchAsset] = useFetchAsset();

  useEffect(() => {
    if (!view) {
      history.replace({ pathname: `/admin/assets/${id}/overview` });
    }
  }, [view, history, id]);

  useEffect(() => {
    if (id) {
      fetchAsset(id)
        .then((res) => setAsset(res))
        .catch(() => {
          history.replace({ pathname: `/admin/assets` });
        });
    }
  }, [id, fetchAsset, history]);

  if (!asset) return <LinearProgress />;

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h3">
          {t('asset')}: {t(asset.name)}
        </Typography>
      </div>
      <Paper className={classes.content}>
        <Switch>
          <Route exact path="/admin/assets/:id/overview" component={() => <Overview asset={asset} />} />
        </Switch>
      </Paper>
      <div />
    </>
  );
};

export default PortfoliosShow;
