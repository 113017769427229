import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import { useFetchPortfolios } from 'store/reducers/portfolios';
import Table from 'components/Table/Index';
import UpdateHoldingDialog from './index/UpdateHoldingDialog';
import UpdateHoldingPricesDialog from './index/UpdateHoldingPricesDialog';
import useToggle from 'hooks/useToggle';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  table: {
    marginTop: 10,
  },
}));

const PortfoliosIndex = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ loading }, fetchPortfolios] = useFetchPortfolios();
  const { fetched, data: portfolios } = useSelector((state) => state.portfolios);

  const [showHoldingDialog, toggleShowHoldingDialog] = useToggle();
  const [showHoldingPricesDialog, toggleShowHoldingPricesDialog] = useToggle();

  useEffect(() => {
    if (!fetched) {
      fetchPortfolios();
    }
  }, [fetched, fetchPortfolios]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Cell: (x) => t(x.value),
      },
      {
        Header: t('share-price'),
        accessor: 'sharePrice',
      },
      {
        Header: t('total-shares'),
        accessor: 'totalShares',
      },
      {
        Header: t('cocoa-owned-shares'),
        accessor: 'cocoaOwnedShares',
      },
    ],
    [t]
  );

  const data = React.useMemo(
    () =>
      portfolios.map((portfolio) => ({
        ...portfolio,
        linkPath: `/admin/portfolios/${portfolio.id}`,
      })),
    [portfolios]
  );
  return (
    <div className={classes.root}>
      <Button
        onClick={toggleShowHoldingDialog}
        startIcon={<AddIcon />}
        fullWidth={false}
        size="small"
        style={{ marginRight: 10 }}
      >
        {t('update-holdings')}
      </Button>
      <Button onClick={toggleShowHoldingPricesDialog} startIcon={<AddIcon />} fullWidth={false} size="small">
        {t('update-holding-prices')}
      </Button>
      <UpdateHoldingDialog show={showHoldingDialog} toggle={toggleShowHoldingDialog} />
      <UpdateHoldingPricesDialog show={showHoldingPricesDialog} toggle={toggleShowHoldingPricesDialog} />
      {!fetched || loading ? (
        <LinearProgress />
      ) : (
        <div className={classes.table}>
          <Table data={data} columns={columns} />
        </div>
      )}
    </div>
  );
};

export default PortfoliosIndex;
