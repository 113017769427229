import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import Table from 'components/Table/Index';
import LinearProgress from '@material-ui/core/LinearProgress';

const Plans = ({ plans }) => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('shares'),
        accessor: 'shares',
      },
    ],
    [t]
  );

  const data = React.useMemo(
    () =>
      plans?.map((portfolio) => ({
        ...portfolio,
        linkPath: `/admin/plans/${portfolio.id}`,
      })),
    [plans]
  );
  return (
    <Grid container direction="column">
      {!plans ? <LinearProgress /> : plans?.length === 0 ? t('no-items') : <Table data={data} columns={columns} />}
    </Grid>
  );
};

Plans.propTypes = {
  plans: PropTypes.array.isRequired,
};

export default Plans;
