import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { formatISODateString } from 'utils/helpers';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: 20,
  },
  list: {
    maxWidth: 500,
    paddingTop: 4,
    marginBottom: 15,
    marginTop: 5,
    '& > li': {
      padding: '5px',
      borderRadius: 4,
      justifyContent: 'space-between',
    },
    '& > li:nth-child(odd)': {
      background: '#E3E3E3',
    },
  },
  subtitle: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: '500',
    marginTop: 10,
    marginBottom: 5,
  },
}));

const Overview = ({ fulfillmentOrder }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const items = useMemo(
    () =>
      [
        { label: t('created'), value: formatISODateString(fulfillmentOrder.createdAt) },
        { label: t('netted'), value: fulfillmentOrder.internallyNettedAt ? 'True' : 'False' },
        { label: t('fulfilled'), value: fulfillmentOrder.fulfilledAt ? 'True' : 'False' },
        fulfillmentOrder.transactionsReportFulfillmentComment
          ? {
              label: t('comment'),
              value: fulfillmentOrder.transactionsReportFulfillmentComment ? 'True' : 'False',
            }
          : false,
      ].filter(Boolean),
    [fulfillmentOrder, t]
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          {fulfillmentOrder.portfolioFulfillmentOrders?.map((order, index) => (
            <React.Fragment key={`portfolio-order-${order.id}`}>
              <Typography variant="subtitle2">
                {t('order')} #{index + 1} ({t('id')}: {order.id}) - {t('portfolio')}: {t(order.portfolio.name)} (
                {t('id')}: {order.portfolio.id})
              </Typography>
              <List className={classes.list}>
                {order.cocoaSharesSold !== null ? (
                  <ListItem>
                    <div>{t('cocoa-shares-sold')}</div>
                    <div>{order.cocoaSharesSold}</div>
                  </ListItem>
                ) : undefined}
                {order.buySharePriceUsed !== null ? (
                  <ListItem>
                    <div>{t('buy-share-price-used')}</div>
                    <div>{order.buySharePriceUsed}</div>
                  </ListItem>
                ) : undefined}
                {order.fulfillmentOrderType !== null ? (
                  <ListItem>
                    <div>{t('fulfillment-order-type')}</div>
                    <div>{order.fulfillmentOrderType}</div>
                  </ListItem>
                ) : undefined}
                {order.moneyToTradeExternally !== null ? (
                  <ListItem>
                    <div>{t('money-to-trade-externally')}</div>
                    <div>{order.moneyToTradeExternally}</div>
                  </ListItem>
                ) : undefined}

                {order.nettedAmount !== null ? (
                  <ListItem>
                    <div>{t('netted-amount')}</div>
                    <div>{order.nettedAmount}</div>
                  </ListItem>
                ) : undefined}
                {order.nettedSharePrice !== null ? (
                  <ListItem>
                    <div>{t('netted-share-price')}</div>
                    <div>{order.nettedSharePrice}</div>
                  </ListItem>
                ) : undefined}
                {order.nettedShares !== null ? (
                  <ListItem>
                    <div>{t('netted-shares')}</div>
                    <div>{order.nettedShares}</div>
                  </ListItem>
                ) : undefined}

                {order.sellSharePriceUsed !== null ? (
                  <ListItem>
                    <div>{t('sell-share-price-used')}</div>
                    <div>{order.sellSharePriceUsed}</div>
                  </ListItem>
                ) : undefined}

                {order.externalSharePrice !== null ? (
                  <ListItem>
                    <div>{t('external-share-price')}</div>
                    <div>{order.externalSharePrice}</div>
                  </ListItem>
                ) : undefined}
                {order.fulfilledBy !== null ? (
                  <ListItem>
                    <div>{t('fulfilled-by')}</div>
                    <div>{order.fulfilledBy.fullName}</div>
                  </ListItem>
                ) : undefined}
                {order.moneyCocoaBoughtSharesFor ? (
                  <ListItem>
                    <div>{t('money-cocoa-bought-shares-for')}</div>
                    <div>{order.moneyCocoaBoughtSharesFor}</div>
                  </ListItem>
                ) : undefined}
                {order.sharesBoughtByCocoa !== null ? (
                  <ListItem>
                    <div>{t('shares-bought-by-cocoa')}</div>
                    <div>{order.sharesBoughtByCocoa}</div>
                  </ListItem>
                ) : undefined}
                {order.sharesToTradeExternally !== null ? (
                  <ListItem>
                    <div>{t('shares-to-trade-externally')}</div>
                    <div>{order.sharesToTradeExternally}</div>
                  </ListItem>
                ) : undefined}
                {order.totalExternalAmountTraded !== null ? (
                  <ListItem>
                    <div>{t('total-external-amount-traded')}</div>
                    <div>{order.totalExternalAmountTraded}</div>
                  </ListItem>
                ) : undefined}
                {order.totalExternalFeesAmount !== null ? (
                  <ListItem>
                    <div>{t('total-external-fees-amount')}</div>
                    <div>{order.totalExternalFeesAmount}</div>
                  </ListItem>
                ) : undefined}
                {order.totalExternalFeesFraction !== null ? (
                  <ListItem>
                    <div>{t('total-external-fees-fraction')}</div>
                    <div>{order.totalExternalFeesFraction}</div>
                  </ListItem>
                ) : undefined}
                {order.transactionsReportFulfillmentComment !== null ? (
                  <ListItem>
                    <div>{t('comment')}</div>
                    <div>{order.transactionsReportFulfillmentComment}</div>
                  </ListItem>
                ) : undefined}

                {order.assetsToBuyForFulfillment && order.assetsToBuyForFulfillment.length > 0 && (
                  <>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                      {t('assets-to-buy')}
                    </Typography>
                    {order.assetsToBuyForFulfillment.map((asset) => (
                      <ListItem key={`assets-for-order-${order.id}-${asset.id}-${asset.unitsToBuy}`}>
                        <Grid container direction="column">
                          <Grid item container justify="space-between">
                            <div>{t('market-ticker-symbol')}</div>
                            <div>{asset.asset.marketTickerSymbol}</div>
                          </Grid>
                          <Grid item container justify="space-between">
                            <div>{t('units-to-buy')}</div>
                            <div>{asset.unitsToBuy}</div>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </>
                )}
              </List>
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">{t('general')}</Typography>
          <List className={classes.list}>
            {items.map((item) => (
              <ListItem key={item.label}>
                <div>{item.label}</div>
                <div>{item.value}</div>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

Overview.propTypes = {
  fulfillmentOrder: PropTypes.object.isRequired,
};

export default Overview;
