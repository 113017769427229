import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {Checkbox, makeStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useApproveWireTransferDeposit, useCompleteWireTransferWithdrawal } from 'store/reducers/transactions';
import LoaderWrapper from 'components/LoaderWrapper';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 16,
    height: '100%',
    '& > div:not(:last-child)': {
      paddingBottom: 16,
    },
  },
  action: {
    flexGrow: 1,
  },
}));

const ApproveTransactionForm = ({ type, transactionId, previousInternalComment, updateTransaction }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ loading: approvingWireTransfer }, approveWireTransferDeposit] = useApproveWireTransferDeposit();
  const [{ loading: completingWireTransfer }, completeWireTransferWithdraw] = useCompleteWireTransferWithdrawal();
  const schema = useMemo(
    () =>
      yup.object().shape({
        amount: type === 'Deposit' ? yup.string().required(t('form-validations.required')) : yup.string(),
        internalComment: yup.string(),
      }),
    [t]
  );

  const { register, errors, handleSubmit, formState } = useForm({
    defaultValues: {
      amount: '',
      internalComment: previousInternalComment,
    },
    resolver: yupResolver(schema),
  });
  const { isSubmitting } = formState;
  const disabled = isSubmitting || approvingWireTransfer || completingWireTransfer;

  const onSubmit = async (data) => {
    if (type === 'Deposit') {
      const transaction = await approveWireTransferDeposit(transactionId, data.amount, data.internalComment, data.notifyUser);
      updateTransaction(transaction);
    } else if (type === 'Withdrawal') {
      const transaction = await completeWireTransferWithdraw(transactionId, data.internalComment, data.notifyUser);
      updateTransaction(transaction);
    }
  };
  return (
    <>
      <Grid container component="form" direction="column" onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        {type === 'Deposit' && (
            <Grid item>
              <TextField
                  disabled={disabled}
                  variant="outlined"
                  name="amount"
                  required
                  label={t('amount')}
                  type="text"
                  inputRef={register}
                  error={Boolean(errors.amount)}
                  helperText={errors.name?.amount}
              />
            </Grid>
        )}
        <Grid item>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="internalComment"
            label={t('internal-comment')}
            multiline
            rows={5}
            type="text"
            inputRef={register}
            error={Boolean(errors.internalComment)}
            helperText={errors.internalComment?.message}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
              disabled={disabled}
              control={
                <Checkbox defaultChecked={true} color="default" />
              }
              name="notifyUser"
              label={t('notify-user')}
              inputRef={register}
              error={Boolean(errors.notifyUser)}
              classes={{ root: classes.formControlLabelRoot }}
          />
        </Grid>
        <Grid container justify="flex-end" alignItems="flex-end" className={classes.action}>
          <LoaderWrapper loading={disabled}>
            <Button
              variant="contained"
              color="primary"
              disabled={false}
              type="submit"
              fullWidth={disabled}
              size="small"
            >
              {t(`approve`)}
            </Button>
          </LoaderWrapper>
        </Grid>
      </Grid>
    </>
  );
};

ApproveTransactionForm.propTypes = {
  type: PropTypes.oneOf(['Deposit', 'Withdrawal']).isRequired,
  updateTransaction: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
};

ApproveTransactionForm.defaultProps = {
  editData: null,
};

export default ApproveTransactionForm;
