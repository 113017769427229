import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetchCurrencies, useToggleCurrency } from 'store/reducers/currencies';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table/Index';
import useToggle from 'hooks/useToggle';

import UpsertDialog from './currencies/UpsertDialog';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  table: {
    marginTop: 10,
  },
  switch: {
    margin: 0,
  },
}));

const Currencies = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { fetched, data: currencies } = useSelector((state) => state.currencies);
  const [editCurrency, setEditCurrency] = useState(null);
  const [{ loading }, fetchCurrencies] = useFetchCurrencies();
  const [, toggleCurrency] = useToggleCurrency();
  const [showUpsertDialog, toggleShowUpsertDialog] = useToggle();

  useEffect(() => {
    if (!fetched) {
      fetchCurrencies();
    }
  }, [fetched, fetchCurrencies]);

  const handleEditCurrency = (currency) => {
    setEditCurrency(currency);
    toggleShowUpsertDialog();
  };

  const handleAddCurrency = () => {
    if (editCurrency) {
      setEditCurrency(null);
    }
    toggleShowUpsertDialog();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('code'),
        accessor: 'code',
      },
      {
        Header: t('symbol'),
        accessor: 'symbol',
      },
      {
        Header: t('enabled'),
        accessor: 'enabled',
        Cell(cellInfo) {
          return (
            <Tooltip title={t('clickToToggleValue')}>
              <Switch
                className={classes.switch}
                color="default"
                checked={cellInfo.value}
                onChange={() => toggleCurrency(cellInfo.value, cellInfo.cell.row.original.id)}
              />
            </Tooltip>
          );
        },
      },
    ],
    [t, toggleCurrency, classes.switch]
  );

  const data = useMemo(() => currencies, [currencies]);

  const actions = [
    {
      type: 'func',
      onClick: handleEditCurrency,
      icon: 'edit',
      tooltip: t('edit'),
    },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div className={classes.root}>
      <Button onClick={handleAddCurrency} startIcon={<AddIcon />} fullWidth={false} size="small">
        {t('addCurrency')}
      </Button>
      <UpsertDialog
        key={`${editCurrency?.id}-upsert-dialog-currency`}
        show={showUpsertDialog}
        toggle={toggleShowUpsertDialog}
        editData={editCurrency ?? null}
      />
      <div className={classes.table}>
        <Table data={data} columns={columns} actions={actions} />
      </div>
    </div>
  );
};

export default Currencies;
