import React, { useMemo, useState } from 'react';
import { Grid, Button, TextField, Typography, makeStyles, Container, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import { useLoginUser } from 'store/reducers/user';
import LoaderWrapper from 'components/LoaderWrapper';
import { forgotPasswordAsync } from 'transfer/accountApi';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: '100%',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    height: '100vh',
    padding: 20,
  },
  logo: {
    height: 100,
    marginBottom: 50,
  },
  underline: {
    '&:before, &:after': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  forgotBtn: {
    minHeight: 'unset',
    fontSize: 14,
  },
  paper: {
    padding: 20,
  },
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const [helperText, setHelperText] = useState(null);
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [{ loading: loadingLoginUser }, loginUser] = useLoginUser();
  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(t('form-validations.email')).required(t('form-validations.required')),
        password: yup.string().required(t('form-validations.required')),
      }),
    [t]
  );
  const { register, errors, setError, watch, clearErrors, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const email = watch('email');
  const loading = loadingLoginUser || submitting;

  const onSubmit = async (data) => {
    setHelperText(null);
    const userData = await loginUser(data.email, data.password);
    if (userData) {
      history.push('/admin');
    } else {
      setHelperText(t('error-messages.incorrectLogin'));
    }
  };

  const handleForgotPassword = async () => {
    clearErrors();
    setHelperText(null);
    setSubmitting(true);
    try {
      await yup
        .object()
        .shape({
          email: yup.string().email(t('form-validations.email')).required(t('form-validations.required')),
        })
        .validate({ email });
      const emailSent = await forgotPasswordAsync(email);
      if (emailSent) {
        setHelperText(t('success-messages.forgotPassword'));
      } else {
        setHelperText(t('error-messages.forgotPassword'));
      }
    } catch (error) {
      setError('email', {
        type: 'manual',
        message: error.message,
      });
    }
    setSubmitting(false);
  };

  return (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Container
        component={Grid}
        maxWidth="sm"
        className={classes.container}
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      >
        <Grid
          item
          container
          style={{ flexGrow: 1 }}
          alignItems="center"
          justify="center"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2} component={Paper} square className={classes.paper}>
            <Grid item xs={12}>
              <TextField
                type="email"
                name="email"
                inputRef={register}
                label={t('email')}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                disabled={loading}
                color="primary"
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                name="password"
                inputRef={register}
                label={t('password')}
                color="primary"
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                disabled={loading}
                InputProps={{
                  classes: {
                    underline: classes.underline,
                  },
                  endAdornment: (
                    <Button
                      variant="text"
                      color="primary"
                      fullWidth={false}
                      className={classes.forgotBtn}
                      disabled={loading}
                      onClick={handleForgotPassword}
                    >
                      {t('forgot?')}
                    </Button>
                  ),
                }}
              />
            </Grid>
            {helperText && (
              <Grid item xs={12}>
                <Typography>{helperText}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <LoaderWrapper loading={loading} color="secondary">
                <Button color="primary" fullWidth type="submit" disabled={loading}>
                  {t('login')}
                </Button>
              </LoaderWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Home;
