import clients from './clients';
import countries from './countries';
import currencies from './currencies';
import fees from './fees';
import portfolios from './portfolios';
import transactions from './transactions';
import user from './user';
import assets from './assets';
import fulfillmentOrders from './fulfillmentOrders';
import ui from './ui';

export default {
  clients,
  countries,
  currencies,
  fees,
  portfolios,
  transactions,
  user,
  assets,
  fulfillmentOrders,
  ui,
};
