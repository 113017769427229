import { createSlice } from '@reduxjs/toolkit';
import useAsyncFn from 'hooks/useAsyncFn';
import { useDispatch } from 'react-redux';
import {
  completeFulfillmentOrderAsync,
  createFulfillmentOrderAsync,
  fetchFulfillmentOrderAsync,
  fetchFulfillmentOrdersAsync,
  netFulfillmentOrderAsync,
} from 'transfer/admin/fulfillmentOrdersApi';

const fulfillmentOrdersSlice = createSlice({
  name: 'fulfillmentOrders',
  initialState: {
    fetched: false,
    data: [],
  },
  reducers: {
    addFulfillmentOrders(state, { payload }) {
      state.data = payload;
      state.fetched = true;
    },
    upsertFulfillmentOrder(state, { payload }) {
      const orderIndex = state.data.findIndex((order) => order.id === payload.id);
      if (orderIndex >= 0) {
        state.data[orderIndex] = payload;
      } else {
        state.data.unshift(payload);
      }
    },
  },
});

export const { addFulfillmentOrders, upsertFulfillmentOrder } = fulfillmentOrdersSlice.actions;

export default fulfillmentOrdersSlice.reducer;

export function useFetchFulfillmentOrders() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const fulfillmentOrders = await fetchFulfillmentOrdersAsync();
    dispatch(addFulfillmentOrders(fulfillmentOrders.list));
    return fulfillmentOrders;
  }, [dispatch]);
}

export function useFetchFulfillmentOrder() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id) => {
      const fulfillmentOrder = await fetchFulfillmentOrderAsync(id);
      dispatch(upsertFulfillmentOrder(fulfillmentOrder));
      return fulfillmentOrder;
    },
    [dispatch]
  );
}

export function useCreateFulfillmentOrder() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const fulfillmentOrder = await createFulfillmentOrderAsync();
    dispatch(upsertFulfillmentOrder(fulfillmentOrder));
    return fulfillmentOrder;
  }, [dispatch]);
}

export function useNetFulfillmentOrder() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id) => {
      const fulfillmentOrder = await netFulfillmentOrderAsync(id);
      dispatch(upsertFulfillmentOrder(fulfillmentOrder));
      return fulfillmentOrder;
    },
    [dispatch]
  );
}

export function useCompleteFulfillmentOrder() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (id, data) => {
      const fulfillmentOrder = await completeFulfillmentOrderAsync(id, data);
      dispatch(upsertFulfillmentOrder(fulfillmentOrder));
      return fulfillmentOrder;
    },
    [dispatch]
  );
}
