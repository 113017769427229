import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    notifications: [],
  },
  reducers: {
    addNotification(state, action) {
      const { key, message, variant } = action.payload;
      state.notifications.push({
        key: key ?? new Date().getTime() + Math.random(),
        message,
        variant: variant ?? 'default',
      });
    },
    removeNotification(state, action) {
      state.notifications = state.notifications.filter((notification) => notification.key !== action.payload);
    },
  },
});

export const { addNotification, removeNotification } = uiSlice.actions;

export default uiSlice.reducer;
