import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormDialog from 'components/Table/FormDialog';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useCompleteFulfillmentOrder } from 'store/reducers/fulfillmentOrders';
import Typography from '@material-ui/core/Typography';
import locale from 'react-json-editor-ajrm/locale/en';
import JSONInput from 'react-json-editor-ajrm';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

const CompleteFulfillmentDialog = ({ show, toggle, id, fulfillmentOrder }) => {
  const { t } = useTranslation(['translation', 'views']);

  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      transactionsReportFulfillmentComment: '',
    },
  });

  const [disabled, setDisabled] = useState(false);
  const [selectedOrderFiles, setSelectedOrderFiles] = useState(undefined);
  const [selectedExecutionFiles, setSelectedExecutionFiles] = useState(undefined);
  const [disabledOrder, setDisabledOrder] = useState(false);
  const [disabledExecution, setDisabledExecution] = useState(false);
  const [, completeFulfillmentOrder] = useCompleteFulfillmentOrder();
  const [moneyCocoaBoughtSharesFor, setMoneyCocoaBoughtSharesFor] = useState();

  const selectOrderFiles = (event) => {
    setSelectedOrderFiles(event.target.files);
    setDisabledOrder(true);
  };
  const selectExecutionFile = (event) => {
    setSelectedExecutionFiles(event.target.files);
    setDisabledExecution(true);
  };

  useEffect(() => {
    if (fulfillmentOrder.portfolioFulfillmentOrders) {
      const temp = {};
      for (let i = 0; i < fulfillmentOrder.portfolioFulfillmentOrders.length; i++) {
        temp[fulfillmentOrder.portfolioFulfillmentOrders[i].portfolio.id] = 0;
      }
      setMoneyCocoaBoughtSharesFor(temp);
    }
  }, [fulfillmentOrder]);

  const upload = async (data) => {
    if (!moneyCocoaBoughtSharesFor) return;
    try {
      setDisabled(true);
      let orderFiles = selectedOrderFiles;
      let executionFiles = selectedExecutionFiles;
      let formData = new FormData();

      if (orderFiles) {
        for (let i = 0; i < orderFiles.length; i++) {
          formData.append(`OrderDataFiles`, orderFiles[i]);
        }
      }
      if (executionFiles) {
        for (let i = 0; i < executionFiles.length; i++) {
          formData.append(`ExecutionDataFiles`, executionFiles[i]);
        }
      }
      formData.append('TransactionsReportFulfillmentComment', data.transactionsReportFulfillmentComment);
      Object.entries(moneyCocoaBoughtSharesFor).forEach(([key, value]) => {
        formData.append(`MoneyCocoaBoughtSharesFor[${key}]`, value);
      });

      await completeFulfillmentOrder(id, formData);
      clearUpload();
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  const clearUpload = () => {
    setDisabled(false);
    setSelectedOrderFiles(undefined);
    setSelectedExecutionFiles(undefined);
    setDisabledOrder(false);
    setDisabledExecution(false);
    reset();
    toggle();
  };

  return (
    <FormDialog
      open={show}
      title={t('views:fulfillment-orders.complete-dialog.title')}
      description={t('views:fulfillment-orders.complete-dialog.description')}
      toggle={clearUpload}
      onSubmit={handleSubmit(upload)}
      submitText={t('fulfill')}
      disabled={disabled}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            name="transactionsReportFulfillmentComment"
            label={t('comment')}
            type="text"
            inputRef={register}
          />
        </Grid>
        <Grid item xs={6}>
          <Button startIcon={<AddIcon />} fullWidth={true} size="small" component={'label'} disabled={disabledOrder}>
            {t('order-data')}
            <input type={'file'} onChange={selectOrderFiles} hidden multiple />
          </Button>
          {selectedOrderFiles &&
            Object.keys(selectedOrderFiles).map((file, index) => (
              <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} key={index}>
                {selectedOrderFiles[file].name}
              </p>
            ))}
        </Grid>
        <Grid item xs={6}>
          <Button
            startIcon={<AddIcon />}
            fullWidth={true}
            size="small"
            component={'label'}
            disabled={disabledExecution}
          >
            {t('execution-data')}
            <input type={'file'} onChange={selectExecutionFile} hidden multiple />
          </Button>
          {selectedExecutionFiles &&
            Object.keys(selectedExecutionFiles).map((file, index) => (
              <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} key={index}>
                {selectedExecutionFiles[file].name}
              </p>
            ))}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {t('views:fulfillment-orders.complete-dialog.money-cocoa-bought-shares-for')}
          </Typography>
          <JSONInput
            placeholder={moneyCocoaBoughtSharesFor ?? {}}
            locale={locale}
            onChange={(value) => setMoneyCocoaBoughtSharesFor(value.jsObject)}
            width="100%"
            height={250}
          />
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('portfolio')}</TableCell>
              <TableCell>{t('id')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fulfillmentOrder.portfolioFulfillmentOrders?.map((o) => (
              <TableRow key={`complete-fulfillment-order-portfolio-${o.portfolio?.id}`}>
                <TableCell>{t(o.portfolio?.name)}</TableCell>
                <TableCell>{o.portfolio?.id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </FormDialog>
  );
};

CompleteFulfillmentDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  fulfillmentOrder: PropTypes.object.isRequired,
};

export default CompleteFulfillmentDialog;
