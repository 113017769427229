import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Button, TextField, Typography, makeStyles, Container, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';

import { useResetPassword } from 'store/reducers/user';
import LoaderWrapper from 'components/LoaderWrapper';
import { ReactComponent as GreenCheckboxIcon } from '../assets/icons/GreenCheckbox.svg';
import { ReactComponent as RedCheckboxIcon } from '../assets/icons/RedCheckbox.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: '100%',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    minHeight: '100vh',
    padding: 20,
  },
  paper: {
    padding: 20,
  },
  underline: {
    '&:before, &:after': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  requirements: {
    padding: '10px 18px',
    borderRadius: 3,
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
  requirementsTitle: {
    marginBottom: 15,
  },
  helperText: {
    padding: '10px 18px',
    borderRadius: 3,
  },
}));

const PasswordReset = () => {
  const classes = useStyles();
  const history = useHistory();
  const [helperText, setHelperText] = useState(null);
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [{ loading: loadingResetPassword }, resetPassword] = useResetPassword();
  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(t('form-validations.email')).required(t('form-validations.required')),
        password: yup
          .string()
          .matches('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})', t('form-validations.password'))
          .required(t('form-validations.required')),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('password'), null], t('form-validations.confirmPassword'))
          .required(t('form-validations.required')),
      }),
    [t]
  );
  const { register, errors, handleSubmit, watch } = useForm({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });
  const loading = loadingResetPassword || submitting;

  const password = watch('password');
  const hasLength = Boolean(password.match('^(?=.{6,})'));
  const hasUppercase = Boolean(password.match('^(?=.*[A-Z])'));
  const hasLowercase = Boolean(password.match('^(?=.*[a-z])'));
  const hasNumber = Boolean(password.match('^(?=.*[0-9])'));

  const onSubmit = async (data) => {
    setSubmitting(true);
    if (submitting) return;
    setHelperText(null);

    const [, token] = document.URL.split('?token=');
    if (token && token.length > 0) {
      const userData = await resetPassword(data.email, data.password, token);
      if (userData) {
        history.push('/admin');
      } else {
        setHelperText(t('error-messages.resetPassword'));
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const [, token] = document.URL.split('?token=');
    if (!token || token.length === 0) {
      history.push('/');
    }
  }, [history]);

  const renderRequirement = (requirementFulfilled, text) => (
    <Typography variant="body1">
      {requirementFulfilled ? <GreenCheckboxIcon /> : <RedCheckboxIcon />}
      {` ${text}`}
    </Typography>
  );

  return (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Container
        component={Grid}
        maxWidth="sm"
        className={classes.container}
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      >
        <Grid
          item
          container
          style={{ flexGrow: 1 }}
          alignItems="center"
          justify="center"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2} component={Paper} square className={classes.paper}>
            <Grid item xs={12}>
              <TextField
                type="email"
                name="email"
                inputRef={register}
                label={t('email')}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                disabled={loading}
                color="primary"
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                name="password"
                inputRef={register}
                label={t('password')}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                disabled={loading}
                color="primary"
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                name="confirmPassword"
                inputRef={register}
                label={t('confirmPassword')}
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword?.message}
                disabled={loading}
                color="primary"
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.requirements}>
                <Typography variant="subtitle2" color="primary" className={classes.requirementsTitle}>
                  {t('password-requirements.title')}
                </Typography>
                <Typography variant="subtitle2" className={classes.requirementsTitle}>
                  {t('password-requirements.subtitle')}
                </Typography>
                {renderRequirement(hasLength, t('password-requirements.length'))}
                {renderRequirement(hasUppercase, t('password-requirements.uppercase'))}
                {renderRequirement(hasLowercase, t('password-requirements.lowercase'))}
                {renderRequirement(hasNumber, t('password-requirements.number'))}
              </div>
            </Grid>
            {helperText && (
              <Grid item xs={12}>
                <Typography color="error" className={classes.helperText}>
                  {helperText}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <LoaderWrapper loading={loading} color="secondary">
                <Button color="primary" fullWidth type="submit" disabled={loading}>
                  {t('updatePassword')}
                </Button>
              </LoaderWrapper>
            </Grid>
            <Grid item xs={12}>
              <Typography>{t('notice-messages.resetPassword')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default PasswordReset;
