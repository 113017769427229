import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import LoaderWrapper from 'components/LoaderWrapper';

const Transition = forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    maxWidth: 500,
    height: 'unset',
    margin: 15,
    borderRadius: 4,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
  },
  closeButton: {},
}));

const FormDialog = ({
  title,
  description,
  open,
  toggle,
  onSubmit,
  disabled,
  loading,
  submitText,
  submitDisabled,
  children,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={toggle}
      fullScreen
      PaperProps={{
        component: 'form',
        onSubmit: onSubmit || (() => {}),
        className: classes.paper,
      }}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle disableTypography className={classes.header}>
        <Typography variant="h6">{title}</Typography>
        <IconButton aria-label="close" onClick={toggle} className={classes.closeButton} disabled={disabled}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{description}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <LoaderWrapper loading={typeof loading === 'boolean' ? loading : disabled}>
          <Button
            variant="contained"
            color="primary"
            disabled={typeof submitDisabled === 'boolean' ? submitDisabled : disabled}
            type="submit"
            fullWidth={false}
            size="small"
          >
            {submitText ?? t('save')}
          </Button>
        </LoaderWrapper>
      </DialogActions>
    </Dialog>
  );
};

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  disabled: PropTypes.bool,
  submitText: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FormDialog.defaultProps = {
  disabled: false,
  onSubmit: false,
  submitText: null,
};

export default FormDialog;
