import axios from 'axios';
import { apiEndpoints } from 'transfer/config';

const accountEndpoint = apiEndpoints.account;

export async function registerWithEmail(data) {
  try {
    const res = await axios.post(`${accountEndpoint}/register`, {
      email: data.email,
      countryOfResidenceId: data.country,
      phoneNumber: data.dialCode + data.phoneNumber,
      enableSmsNotifications: data.notifications,
      givenName: data.firstName,
      familyName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      password: data.password,
    });
    return res.data;
  } catch {
    return false;
  }
}

export async function authenticateUser() {
  try {
    const res = await axios.get(`${accountEndpoint}`);
    return res.data;
  } catch {
    return false;
  }
}

export async function loginUser(email, password) {
  try {
    const res = await axios.post(`${accountEndpoint}/login`, {
      email,
      password,
    });
    return res.data;
  } catch {
    return false;
  }
}

export async function logoutUserAsync() {
  try {
    await axios.post(`${accountEndpoint}/logout`);
    return true;
  } catch {
    return false;
  }
}

export async function changePasswordAsync(currentPassword, newPassword) {
  try {
    await axios.post(`${accountEndpoint}/change-password`, {
      currentPassword,
      newPassword,
    });
    return true;
  } catch {
    return false;
  }
}

export async function forgotPasswordAsync(email) {
  try {
    await axios.post(`${accountEndpoint}/forgot-password`, {
      email,
    });
    return true;
  } catch {
    return false;
  }
}

export async function resetPasswordAsync(email, password, token) {
  try {
    const res = await axios.post(`${accountEndpoint}/reset-password`, {
      email,
      token,
      password,
    });
    return res.data;
  } catch {
    return false;
  }
}
