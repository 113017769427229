import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import useAsyncFn from 'hooks/useAsyncFn';
import {
  authenticateUser,
  loginUser,
  logoutUserAsync,
  registerWithEmail,
  resetPasswordAsync,
} from 'transfer/accountApi';

const initialState = {
  fetched: false,
  authenticated: false,
  data: {
    id: '',
    roles: [],
    email: '',
    familyName: '',
    givenName: '',
    phoneNumber: '',
    dateOfBirth: null,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: { ...initialState },
  reducers: {
    update(state, { payload }) {
      return {
        ...state,
        authenticated: true,
        fetched: true,
        data: {
          ...payload,
        },
      };
    },
    removeAuth() {
      return {
        ...initialState,
        fetched: true,
        authenticated: false,
      };
    },
  },
});

export const { update, removeAuth } = userSlice.actions;

export default userSlice.reducer;

export function useRegisterUser() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (data) => {
      const user = await registerWithEmail(data);
      if (user) dispatch(update(user));
      return user;
    },
    [dispatch]
  );
}

export function useResetPassword() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (email, password, token) => {
      const user = await resetPasswordAsync(email, password, token);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useAuthenticateUser() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const user = await authenticateUser();
    if (user) {
      dispatch(update(user));
    }
    return user;
  }, [dispatch]);
}

export function useUpdateUser() {
  return useAuthenticateUser();
}

export function useLoginUser() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (email, password) => {
      const user = await loginUser(email, password);
      if (user) {
        dispatch(update(user));
      }
      return user;
    },
    [dispatch]
  );
}

export function useLogoutUser() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const loggedOut = await logoutUserAsync();
    if (loggedOut) {
      dispatch(removeAuth());
    }
    return loggedOut;
  }, [dispatch]);
}
