import { createSlice } from '@reduxjs/toolkit';
import useAsyncFn from 'hooks/useAsyncFn';
import {
  createManagementFeeAsync,
  fetchManagementFeesAsync,
  fetchManagementPortfolioFeesAsync,
} from 'transfer/admin/feesApi';
import { useDispatch } from 'react-redux';

const feesSlice = createSlice({
  name: 'fees',
  initialState: {
    fetched: false,
    data: [],
  },
  reducers: {
    addManagementFees(state, { payload }) {
      state.data = [...payload, ...state.data];
      state.fetched = true;
    },
    addNewManagementFees(state, { payload }) {
      state.data = [...payload, ...state.data];
    },
  },
});

export const { addManagementFees, addNewManagementFees } = feesSlice.actions;

export default feesSlice.reducer;

export function useFetchManagementFees() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const fees = await fetchManagementFeesAsync();
    dispatch(addManagementFees(fees.list));
    return fees;
  }, [dispatch]);
}

export function useCreateManagementFees() {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const fees = await createManagementFeeAsync();
    dispatch(addNewManagementFees(fees));
    return fees;
  }, [dispatch]);
}

export function useFetchPortfolioManagementFees() {
  const dispatch = useDispatch();
  return useAsyncFn(
    async (portfolioId) => {
      const fees = await fetchManagementPortfolioFeesAsync(portfolioId);
      return fees.list;
    },
    [dispatch]
  );
}
