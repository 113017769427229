import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

import { useCreateManagementFees, useFetchManagementFees } from 'store/reducers/fees';
import LoaderWrapper from 'components/LoaderWrapper';
import { useSelector } from 'react-redux';
import { formatISODateString } from 'utils/helpers';
import Table from 'components/Table/Index';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  loaderWrapper: {
    display: 'inline-block',
  },
  table: {
    marginTop: 10,
  },
}));

const FeeIndex = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ loading: creatingManagementFees }, createManagementFees] = useCreateManagementFees();
  const { data: feeOrders, fetched } = useSelector((state) => state.fees);
  const [{ loading: fetchingFees, error: errorFetchingFees }, fetchManagementFees] = useFetchManagementFees();

  useEffect(() => {
    if (!fetched && !fetchingFees && !errorFetchingFees) {
      fetchManagementFees();
    }
  }, [fetched, fetchingFees, fetchManagementFees, errorFetchingFees]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('date'),
        accessor: 'createdAt',
        Cell: (x) => {
          return formatISODateString(x.value);
        },
      },
      {
        Header: t('portfolio-name'),
        accessor: 'portfolio.name',
        Cell: (x) => {
          return t(x.value);
        },
      },
      {
        Header: t('total-shares'),
        accessor: 'totalShares',
      },
      {
        Header: t('total-value'),
        accessor: 'totalValue',
      },
    ],
    [t]
  );

  const data = useMemo(
    () =>
      feeOrders?.map((feeOrders) => ({
        ...feeOrders,
        /* linkPath: `/admin/management-fees/${feeOrders.id}`, */
      })),
    [feeOrders]
  );
  return (
    <div className={classes.root}>
      <LoaderWrapper loading={creatingManagementFees} className={classes.loaderWrapper}>
        <Button
          startIcon={<AddIcon />}
          fullWidth={false}
          size="small"
          disabled={creatingManagementFees}
          onClick={createManagementFees}
        >
          {t('take-out-management-fees')}
        </Button>
      </LoaderWrapper>
      {!fetched && !errorFetchingFees ? (
        <LinearProgress />
      ) : (
        <div className={classes.table}>
          <Table columns={columns} data={data} />
        </div>
      )}
    </div>
  );
};

export default FeeIndex;
