export default {
  root: {
    minHeight: 48,
    fontSize: 16,
  },
  contained: {
    fontSize: 16,
  },
  sizeSmall: {
    minHeight: 38,
    fontSize: 15,
  },
  sizeLarge: {
    minHeight: 58,
  },
  outlinedPrimary: {
    color: 'black',
  },
};
