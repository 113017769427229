import { useCallback, useState } from 'react';

export default function useArrayToggle(initialValues = []) {
  const [values, setValues] = useState(initialValues);

  // Returns true if the value was added and false if not.
  const toggleValue = useCallback(
    (newValue) => {
      let newValues = [...values];
      const indexOfValue = values.indexOf(newValue);
      if (indexOfValue >= 0) {
        newValues.splice(indexOfValue, 1);
        setValues(newValues);
        return false;
      }
      newValues = [...newValues, newValue];
      setValues(newValues);
      return true;
      // eslint-disable-next-line
    },
    [values]
  );

  const reset = useCallback(() => {
    setValues(initialValues);
  }, [initialValues]);

  /* const setMultipleValues = (newValues) => {
    newValues.filter((v) => !values.includes(v));
    setValues([...values, ...newValues]);
  }; */

  return { values, toggleValue, reset };
}
