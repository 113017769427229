/* import { createMuiTheme } from '@material-ui/core';

// Use theme if media queries are needed when setting the font sizes.
const theme = createMuiTheme(); */

export default {
  button: {
    textTransform: 'unset',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '0.4px',
  },
  h2: {
    fontSize: 36,
    fontWeight: 500,
    lineHeight: '45px',
    letterSpacing: '0.702px',
  },
  h3: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '40px',
    letterSpacing: '0.468px',
  },
  h4: {
    fontSize: 21,
    fontWeight: 400,
    lineHeight: '31px',
    letterSpacing: '0.3857143px',
  },
  h5: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.3857143px',
  },
  h6: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.3857143px',
  },
  subtitle1: {
    fontSize: 26,
    fontWeight: 400,
    lineHeight: '31px',
    letterSpacing: '0.507',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 550,
    lineHeight: '17px',
    letterSpacing: '0.875px',
  },
  body1: {
    fontSize: 14,
  },
  body2: {
    fontSize: 10,
    letterSpacing: '0.1875px',
  },
};
