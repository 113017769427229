import axios from 'axios';
import { apiEndpoints } from 'transfer/config';

const currenciesEndpoint = apiEndpoints.admin.currencies;

export async function fetchCurrenciesAsync() {
  try {
    const res = await axios.get(`${currenciesEndpoint}`);
    return res.data;
  } catch {
    return false;
  }
}
export async function createCurrencyAsync(data) {
  try {
    const res = await axios.post(`${currenciesEndpoint}`, data);
    return res.data;
  } catch {
    return false;
  }
}
export async function updateCurrencyAsync(id, data) {
  try {
    const res = await axios.put(`${currenciesEndpoint}/${id}`, data);
    return res.data;
  } catch {
    return false;
  }
}
export async function enableCurrencyAsync(currencyId) {
  try {
    await axios.post(`${currenciesEndpoint}/enable/${currencyId}`);
    return true;
  } catch {
    return false;
  }
}
export async function disableCurrencyAsync(currencyId) {
  try {
    await axios.post(`${currenciesEndpoint}/disable/${currencyId}`);
    return true;
  } catch {
    return false;
  }
}
