import React, { useEffect, useMemo } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { useSelector } from 'react-redux';
import Table from 'components/Table/Index';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useFetchCountries, useToggleCountry } from 'store/reducers/countries';

const useStyles = makeStyles(() => ({
  content: {
    padding: 20,
  },
  switch: {
    margin: 0,
  },
}));

const Countries = () => {
  const classes = useStyles();
  const { view } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [{ loading }, toggleCountry] = useToggleCountry();
  const [, fetchCountries] = useFetchCountries();
  const { fetched, data: countries } = useSelector((state) => state.countries);

  useEffect(() => {
    if (!view) {
      history.replace({ pathname: '/admin/countries/all' });
    }
  }, [view, history]);

  useEffect(() => {
    if (!fetched) {
      fetchCountries();
    }
  }, [fetched, fetchCountries]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: 'ISO 2',
        accessor: 'id',
      },
      {
        Header: 'ISO 3',
        accessor: 'isO3',
      },
      {
        Header: t('enabled'),
        accessor: 'enabled',
        Cell(cellInfo) {
          return (
            <Tooltip title={t('clickToToggleValue')}>
              <Switch
                className={classes.switch}
                color="default"
                checked={cellInfo.value}
                onChange={() => toggleCountry(cellInfo.value, cellInfo.cell.row.original.id)}
              />
            </Tooltip>
          );
        },
      },
    ],
    [t, toggleCountry, classes.switch]
  );

  const data = useMemo(
    () =>
      countries
        ?.filter((country) => (view === 'enabled' ? country.enabled : view === 'disabled' ? !country.enabled : true))
        .map((country) => ({
          name: country.name,
          id: country.id,
          isO3: country.isO3,
          enabled: country.enabled,
        })),
    [countries, view]
  );

  return (
    <>
      <AppBar position="static">
        <Tabs value={view ?? 'all'}>
          <Tab label={t('allCountries')} component={Link} to="/admin/countries/all" value="all" />
          <Tab label={t('enabledCountries')} component={Link} to="/admin/countries/enabled" value="enabled" />
          <Tab label={t('disabledCountries')} component={Link} to="/admin/countries/disabled" value="disabled" />
        </Tabs>
      </AppBar>
      <div className={classes.content}>
        <Table columns={columns} data={data} disabled={loading} />
      </div>
    </>
  );
};

export default Countries;
