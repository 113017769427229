import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import { useFetchAssets } from 'store/reducers/assets';
import Table from 'components/Table/Index';
import UpsertAssetDialog from './index/UpsertAssetDialog';
import useToggle from 'hooks/useToggle';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  table: {
    marginTop: 10,
  },
}));

const AssetsIndex = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ loading }, fetchAssets] = useFetchAssets();
  const { fetched, data: assets } = useSelector((state) => state.assets);

  const [editAsset, setEditAsset] = useState(null);
  const [showUpsertDialog, toggleShowUpsertDialog] = useToggle();

  useEffect(() => {
    if (!fetched) {
      fetchAssets();
    }
  }, [fetched, fetchAssets]);

  const handleAddAsset = () => {
    if (editAsset) {
      setEditAsset(null);
    }
    toggleShowUpsertDialog();
  };

  const handleEditAsset = (asset) => {
    setEditAsset(asset);
    toggleShowUpsertDialog();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t('marketTickerSymbol'),
        accessor: 'marketTickerSymbol',
      },
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('isin'),
        accessor: 'isin',
      },
      {
        Header: t('colorHEXvalue'),
        accessor: 'color',
      },
    ],
    [t]
  );

  const actions = [
    {
      type: 'func',
      onClick: handleEditAsset,
      icon: 'edit',
      tooltip: t('edit'),
    },
  ];

  const data = React.useMemo(
    () =>
      assets.map((asset) => ({
        ...asset,
        linkPath: `/admin/assets/${asset.id}`,
      })),
    [assets]
  );
  return (
    <div className={classes.root}>
      <Button onClick={handleAddAsset} startIcon={<AddIcon />} fullWidth={false} size="small">
        {t('addAssets')}
      </Button>
      <UpsertAssetDialog
        key={`${editAsset?.id}-upsert-dialog-asset`}
        show={showUpsertDialog}
        toggle={toggleShowUpsertDialog}
        editData={editAsset ?? null}
      />
      {!fetched || loading ? (
        <LinearProgress />
      ) : (
        <div className={classes.table}>
          <Table data={data} columns={columns} actions={actions} />
        </div>
      )}
    </div>
  );
};

export default AssetsIndex;
