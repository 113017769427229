import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';

import FormDialog from 'components/Table/FormDialog';
import { useCreateCurrency, useUpdateCurrency } from 'store/reducers/currencies';

const useStyles = makeStyles(() => ({
  formControlLabelRoot: {
    marginLeft: -8,
  },
}));

const UpsertDialog = ({ show, toggle, editData }) => {
  const classes = useStyles();
  const { t } = useTranslation(['translation', 'views']);
  const [{ loading: loadingCreate }, createCurrency] = useCreateCurrency();
  const [{ loading: loadingUpdate }, updateCurrency] = useUpdateCurrency();
  const disabled = loadingCreate || loadingUpdate;
  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('form-validations.required')),
        code: yup
          .string()
          .max(5, t('form-validations.not-longer-than', { value: 5 }))
          .required(t('form-validations.required')),
        symbol: yup
          .string()
          .max(5, t('form-validations.not-longer-than', { value: 5 }))
          .required(t('form-validations.required')),
        minimumDepositAmount: yup
          .number()
          .positive(t('form-validations.positive-value'))
          .required(t('form-validations.required')),
        maximumDepositAmount: yup
          .number()
          .positive(t('form-validations.positive-value'))
          .required(t('form-validations.required')),
        beneficiaryName: yup.string(),
        bankName: yup.string(),
        iban: yup.string().max(34, t('form-validations.not-longer-than', { value: 34 })),
        accountNumber: yup.string(),
        swift: yup.string(),
        address: yup.string(),
      }),
    [t]
  );

  const { register, errors, watch, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const enabled = watch('enabled');

  const onSubmit = async (data) => {
    if (editData) {
      if (
        await updateCurrency(editData.id, {
          ...data,
          id: editData.id,
        })
      ) {
        toggle();
      }
    } else if (await createCurrency(data)) {
      toggle();
    }
  };

  useEffect(() => {
    reset({
      name: editData?.name ?? '',
      code: editData?.code ?? '',
      symbol: editData?.symbol ?? '',
      minimumDepositAmount: editData?.minimumDepositAmount ?? '',
      maximumDepositAmount: editData?.maximumDepositAmount ?? '',
      enabled: editData?.enabled ?? false,
      beneficiaryName: editData?.beneficiaryName ?? '',
      bankName: editData?.bankName ?? '',
      iban: editData?.iban ?? '',
      accountNumber: editData?.accountNumber ?? '',
      sortCode: editData?.sortCode ?? '',
      swift: editData?.swift ?? '',
      address: editData?.address ?? '',
    });
  }, [reset, editData]);

  return (
    <FormDialog
      open={show}
      title={t(`views:admin.currencies.upsert-dialog.${editData ? 'edit' : 'create'}-title`)}
      description={t(`views:admin.currencies.upsert-dialog.${editData ? 'edit' : 'create'}-description`)}
      toggle={toggle}
      disabled={disabled}
      onSubmit={handleSubmit(onSubmit)}
      submitText={editData ? t('update') : t('create')}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="name"
            required
            label={t('name')}
            type="text"
            inputRef={register}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="code"
            required
            label={t('code')}
            type="text"
            inputRef={register}
            error={Boolean(errors.code)}
            helperText={errors.code?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="symbol"
            required
            label={t('symbol')}
            type="text"
            inputRef={register}
            error={Boolean(errors.symbol)}
            helperText={errors.symbol?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="minimumDepositAmount"
            required
            label={t('minimum-deposit-amount')}
            type="number"
            inputRef={register}
            error={Boolean(errors.minimumDepositAmount)}
            helperText={errors.minimumDepositAmount?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="maximumDepositAmount"
            required
            label={t('maximum-deposit-amount')}
            type="number"
            inputRef={register}
            error={Boolean(errors.maximumDepositAmount)}
            helperText={errors.maximumDepositAmount?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{t('enableCurrency')}?</Typography>
          <FormControlLabel
            disabled={disabled}
            control={
              <Switch defaultChecked={editData?.enabled ?? false} color="default" inputRef={register} name="enabled" />
            }
            label={enabled ? t('yes') : t('no')}
            classes={{ root: classes.formControlLabelRoot }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{t('views:admin.currencies.upsert-dialog.bank-account-details-title')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="beneficiaryName"
            label={t('beneficiaryName')}
            type="text"
            inputRef={register}
            error={Boolean(errors.beneficiaryName)}
            helperText={errors.beneficiaryName?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="bankName"
            label={t('bankName')}
            type="text"
            inputRef={register}
            error={Boolean(errors.bankName)}
            helperText={errors.bankName?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="iban"
            label={t('iban')}
            type="text"
            inputRef={register}
            error={Boolean(errors.iban)}
            helperText={errors.iban?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="accountNumber"
            label={t('accountNumber')}
            type="text"
            inputRef={register}
            error={Boolean(errors.accountNumber)}
            helperText={errors.accountNumber?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="sortCode"
            label={t('sortCode')}
            type="text"
            inputRef={register}
            error={Boolean(errors.sortCode)}
            helperText={errors.sortCode?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="swift"
            label={t('swiftCode')}
            type="text"
            inputRef={register}
            error={Boolean(errors.swift)}
            helperText={errors.swift?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            variant="outlined"
            name="address"
            multiline
            rows={6}
            label={t('address')}
            type="text"
            inputRef={register}
            error={Boolean(errors.address)}
            helperText={errors.address?.message}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

UpsertDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  editData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    beneficiaryName: PropTypes.string,
    bankName: PropTypes.string,
    iban: PropTypes.string,
    accountNumber: PropTypes.string,
    swift: PropTypes.string,
    address: PropTypes.string,
  }),
};

UpsertDialog.defaultProps = {
  editData: null,
};

export default UpsertDialog;
