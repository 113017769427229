import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Icon, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

const RowActions = ({ rowData, actions }) => {
  return (
    <>
      {actions.map((action) => {
        const buttonProps = {};
        if (action.type === 'func') {
          buttonProps.onClick = (ev) => {
            ev.preventDefault();
            action.onClick(rowData);
          };
        } else if (action.type === 'link') {
          buttonProps.component = Link;
          buttonProps.to = action.linkTo(rowData);
        }

        const ButtonComponent = (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <IconButton key={`row-action-button-${rowData.id}`} {...buttonProps} size="small">
            <Icon>{typeof action.icon === 'function' ? action.icon(rowData) : action.icon}</Icon>
          </IconButton>
        );
        if (action.tooltip) {
          return (
            <Tooltip
              key={`row-action-tooltip-${rowData.id}`}
              arrow
              title={typeof action.tooltip === 'function' ? action.tooltip(rowData) : action.tooltip}
            >
              {ButtonComponent}
            </Tooltip>
          );
        }
        return ButtonComponent;
      })}
    </>
  );
};

RowActions.propTypes = {
  rowData: PropTypes.object.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['link', 'func']),
      linkTo: PropTypes.func, // If type is link this should be a function to generate the link. Row data will be passed as an argument
      onClick: PropTypes.func, // If type is func this will be called and row data will be passed as an argument.
      icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string]), // Can be any material icon string or a function returning a string (taking row data as argument)
      tooltip: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    })
  ).isRequired,
};

export default RowActions;
