import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import RemoveRoleIcon from '@material-ui/icons/NotInterested';
import AddRoleIcon from '@material-ui/icons/VerifiedUser';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { occupations } from 'utils/enums';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  list: {
    maxWidth: 500,
    paddingTop: 4,
    marginBottom: 15,
    '& > li': {
      padding: '5px 0px',
      justifyContent: 'space-between',
    },
  },
  actions: {
    display: 'inline-block',
    padding: 10,
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  button: {
    marginTop: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));

const Overview = ({ user, disabled, removeVerifiedClientRole, addVerifiedClientRole }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: countries } = useSelector((state) => state.countries);
  const { client } = user;
  const { address, bankAccount, legalCompliance } = client;

  const occupation = Object.entries(occupations).find(([, value]) => value === client.occupation);

  const userInfoItems = [
    { label: t('name'), value: user.fullName },
    { label: t('email'), value: user.email },
    { label: t('emailConfirmed'), value: user.emailConfirmed ? t('yes') : t('no') },
    { label: t('phoneNumber'), value: user.phoneNumber },
    { label: t('nationality'), value: countries.find((c) => c.id === client?.nationality?.id)?.name },
    { label: t('countryOfBirth'), value: countries.find((c) => c.id === client.countryOfBirth?.id)?.name },
    { label: t('occupation'), value: occupation && t(`occupations.${occupation[0]}`) },
  ];
  const addressItems = [
    { label: t('countryOfResidence'), value: countries?.find((c) => c.id === address?.country?.id)?.name },
    { label: t('city'), value: address?.city },
    { label: t('region'), value: address?.region },
    { label: t('zipCode'), value: address?.zipCode },
    { label: t('addressLineNumber', { number: 1 }), value: address?.line1 },
    { label: t('addressLineNumber', { number: 2 }), value: address?.line2 },
    { label: t('addressLineNumber', { number: 3 }), value: address?.line3 },
  ];
  const bankAccountItems = [
    { label: t('accountHolderName'), value: bankAccount?.accountHolderName },
    { label: t('accountNumber'), value: bankAccount?.accountNumber },
    { label: t('bankName'), value: bankAccount?.bankName },
    { label: t('swiftCode'), value: bankAccount?.swiftCode },
  ];
  const legalComplianceItems = [
    {
      label: t('legal-compliance.politicallyExposed'),
      value: legalCompliance?.politicallyExposed ? t('yes') : t('no'),
    },
    {
      label: t('legal-compliance.controlPersonOfPublicCompany'),
      value: legalCompliance?.controlPersonOfPublicCompany ? t('yes') : t('no'),
    },
    {
      label: t('legal-compliance.affiliatedWithBrokerage'),
      value: legalCompliance?.affiliatedWithBrokerage ? t('yes') : t('no'),
    },
  ];

  const renderItemsList = (title, items) => {
    const filteredItems = items.filter((item) => Boolean(item.value));
    if (filteredItems.length === 0) return null;

    return (
      <>
        <Typography variant="subtitle2">{title.toUpperCase()}</Typography>
        <List className={classes.list}>
          {filteredItems.map((item) => (
            <ListItem key={item.label}>
              <div>{item.label}</div>
              <div>{item.value}</div>
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={8}>
        {renderItemsList(t('userInfo'), userInfoItems)}
        {renderItemsList(t('address'), addressItems)}
        {renderItemsList(t('bankAccount'), bankAccountItems)}
        {renderItemsList(t('legalCompliance'), legalComplianceItems)}
      </Grid>
      <Grid item xs={4} container justify="flex-end" alignItems="flex-start">
        <div className={classes.actions}>
          <Typography variant="subtitle2">{t('actions').toUpperCase()}</Typography>
          {user.roles.includes('VerifiedClient') ? (
            <Button
              className={classes.button}
              variant="text"
              color="default"
              fullWidth={false}
              disabled={disabled}
              startIcon={<RemoveRoleIcon />}
              onClick={removeVerifiedClientRole}
            >
              Remove verified client role
            </Button>
          ) : (
            <Button
              className={classes.button}
              variant="text"
              color="default"
              fullWidth={false}
              disabled={disabled}
              startIcon={<AddRoleIcon />}
              onClick={addVerifiedClientRole}
            >
              Add verified client role
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

Overview.propTypes = {
  user: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  removeVerifiedClientRole: PropTypes.func.isRequired,
  addVerifiedClientRole: PropTypes.func.isRequired,
};

export default Overview;
