import axios from 'axios';

import { apiEndpoints } from 'transfer/config';

const transactionsEndpoints = apiEndpoints.admin.transactions;

// Todo: fix pagination later
export async function fetchTransactionsAsync(pageSize = 999) {
  const res = await axios.get(`${transactionsEndpoints}?pageSize=${pageSize}`);
  return res.data;
}
// Todo: fix pagination later
export async function fetchPendingWireTransactionsAsync(pageSize = 999) {
  const res = await axios.get(`${transactionsEndpoints}/pending/wire?pageSize=${pageSize}`);
  return res.data;
}

export async function fetchTransactionAsync(id) {
  const res = await axios.get(`${transactionsEndpoints}/${id}`);
  return res.data;
}

export async function approveWireTransferDepositAsync(id, approvedAmount, internalComment, notifyUser) {
  const res = await axios.post(`${transactionsEndpoints}/${id}/wire/deposit/approve`, {
    approvedAmount,
    internalComment,
    notifyUser,
  });
  return res.data;
}

export async function completeWireTransferWithdrawalAsync(id, internalComment, notifyUser) {
  const res = await axios.post(`${transactionsEndpoints}/${id}/wire/withdrawal/approve`, {
    internalComment,
    notifyUser,
  });
  return res.data;
}

export async function failWireTransferAsync(id, rejectionComment, internalComment, notifyUser) {
  const res = await axios.post(`${transactionsEndpoints}/${id}/wire/fail`, {
    rejectionComment,
    internalComment,
    notifyUser,
  });
  return res.data;
}

export async function setWireTransferPendingAsync(id, internalComment) {
  const res = await axios.post(`${transactionsEndpoints}/${id}/wire/pending`, {
    internalComment,
  });
  return res.data;
}
