import axios from 'axios';
import { apiEndpoints } from 'transfer/config';

const countriesEndpoint = apiEndpoints.admin.countries;

export async function fetchAllCountriesAsync() {
  try {
    const res = await axios.get(`${countriesEndpoint}`);
    return res.data;
  } catch {
    return false;
  }
}
export async function enableCountryAsync(countryId) {
  try {
    await axios.post(`${countriesEndpoint}/enable/${countryId}`);
    return true;
  } catch {
    return false;
  }
}
export async function disableCountryAsync(countryId) {
  try {
    await axios.post(`${countriesEndpoint}/disable/${countryId}`);
    return true;
  } catch {
    return false;
  }
}
