import { makeStyles } from '@material-ui/core/styles';
import { SIDE_DRAWER_WIDTH } from 'utils/constants';

export default (theme) =>
  makeStyles(() => ({
    main: {
      backgroundColor: theme.palette.accent.gainsboro,
      minHeight: '100vh',
      fontSize: 14,
      marginLeft: SIDE_DRAWER_WIDTH,
    },
    // Global styles below
    '@global': {
      body: {
        backgroundColor: '#FFFFFF',
        position: 'relative',
        height: '100vh',
      },
      a: {
        textDecoration: 'none',
        textDecorationColor: 'none',
        color: 'inherit',
        '&: -webkit-any-link': {
          color: 'black',
        },
      },
    },
  }));
