import axios from 'axios';
import { removeAuth } from 'store/reducers/user';
import { baseApiUrl } from 'transfer/baseApiConfig';
import { addNotification } from 'store/reducers/ui';

console.log('process.env', process.env);

export const apiEndpoints = {
  account: '/account',
  countries: '/countries',
  admin: {
    currencies: '/admin/currencies',
    onfido: '/admin/onfido',
    countries: '/admin/countries',
    users: '/admin/users',
    transactions: '/admin/transactions',
    fees: '/admin/fees',
    portfolios: '/admin/portfolios',
    assets: '/admin/assets',
    fulfillmentOrders: '/admin/fulfillmentOrders',
  },
};

export const initApi = (history, store) => {
  // Response interceptor
  axios.defaults.baseURL = baseApiUrl;
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      if (error?.response?.status === 401) {
        // Remove authentication status in redux
        store.dispatch(removeAuth());
        if (window?.location?.pathname?.includes('admin')) {
          history.push('/');
        }
      } else {
        const errorResponse = error?.response?.data;
        if (errorResponse) {
          store.dispatch(
            addNotification({
              message: `Title: ${errorResponse.title}\nType: ${errorResponse.type}\nTraceId: ${errorResponse.traceId}\n Instance: ${errorResponse.instance}`,
              variant: 'error',
            })
          );
        }
      }
      return Promise.reject(error);
    }
  );
};
