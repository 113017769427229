export const roles = {
  Admin: 1,
  VerifiedUser: 2,
};

export const onfidoCheckStatus = {
  NotSent: 0,
  Processing: 1,
  Reopened: 2,
  CompletedClear: 3,
  CompletedConsider: 4,
};

export const occupations = {
  Employed: 0,
  SelfEmployed: 1,
  Retired: 2,
  Unemployed: 3,
  FinanciallyIndependent: 4,
  Student: 5,
};

export const AssetClassEnum = {
  Equities: 0,
  Sukuk: 1,
  RealEstate: 2,
  Gold: 3,
  Cash: 4,
};
